import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Tag,
  Input,
  Flex,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import { ImCross, ImCheckmark } from "react-icons/im";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import ModalTag from "./ModalTag";
import { chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import GlobalFilter from "../../helper/globalFilter";
import "../../containers/table/table.css";
import { useHistory } from "react-router-dom";

function Portfolio() {
  const history = useHistory();
  const [kasi, setKasi] = useState([]);
  const [tagUpdateId, setTagUpdateId] = useState("");
  // const [tagDetail, setTagDetail] = useState([]);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [alertMassageUpdate, setalertMassageUpdate] = useState(false);
  const [alertMassageUpdateFailed, setalertMassageUpdateFailed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [load, setLoad] = useState(false);

  let initialValues = {
    name: "",
    kasiId: "",
  };

  const [collection, setCollection] = useState(initialValues);

  const submitUpdate = async (e) => {
    setLoad(true);
    await apiPos
      .patch(`/v1/kasitag/${tagUpdateId}`, collection, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassageUpdate(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageUpdateFailed(true);
      });
    setLoad(false);
    onClose();
    getAPI();
    setTimeout(function () {
      setalertMassageUpdate(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageUpdateFailed(false);
    }, 3000);
  };

  // handle on change untuk di kirim ke component ModalTag
  const handleOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
    // setTagDetail({
    //   ...tagDetail,
    //   [e.target.name]: e.target.value,
    // });
  };

  const getAPI = () => {
    apiPos
      .get(`/v1/userkasi?userId=${Cookies.get("userID")}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setKasi(res.data.data.userkasi);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openModal = (id, kasiId) => {
    console.log("ini ID", id);
    console.log("ini kasiId", kasiId);
    setCollection(initialValues);
    setTagUpdateId(id);
    //request API for modal
    //api to show detail user
    apiPos
      .get(`/v1/kasitag?id=${id}&kasiId=${kasiId}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        if (res.data.data.kasitags[0] == null) {
          setCollection(initialValues);
          // setTagDetail(initialValues)
        } else {
          // setTagDetail(res.data.data.kasitags[0])
          setCollection(res.data.data.kasitags[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    onOpen();
  };

  //api to show table all TAG
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  // REACT TABLE START
  const kasiData = React.useMemo(() => [...kasi], [kasi]);

  const kasiColumns = React.useMemo(
    () => [
      {
        Header: "Nama Produk",
        accessor: "userkasiName",
      },
      // {
      //   Header: "Deskripsi",
      //   accessor: "userkasiDescription",
      // },
      {
        Header: "Bidang Jasa",
        accessor: "kasi",
        Cell: ({ row }) => row.original.kasi && row.original.kasi.name,
      },
      // {
      //   Header: "Harga",
      //   accessor: "rate",
      // },
      // {
      //   Header: "Jumlah Portfolio",
      //   accessor: "portfolio",
      //   Cell: ({ row }) => row.original.portfolio && row.original.portfolio.length,
      // },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Detail",
        Header: "Detail",
        Cell: ({ row }) => {
          return (
            <>
              <HStack>
                <Box>
                  <Button size="sm" colorScheme="linkedin" onClick={() => history.replace(`/portfoliodetail?id=${row.original.id}`)}>
                    Edit
                  </Button>
                </Box>
              </HStack>
            </>
          );
        },
      },
    ]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state, page, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, gotoPage, pageCount } = useTable(
    { columns: kasiColumns, data: kasiData },
    useGlobalFilter,
    useSortBy,
    tableHooks,
    usePagination
  );

  const { pageIndex } = state;
  // REACT TABLE END

  //DELETE TAG  START
  const deleteTag = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);

    await apiPos
      .delete(`/v1/kasitag/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };
  //DELETE TAG END
  return (
    <Fragment>
      {" "}
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Portfolio</Center>
            </Heading>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Tag Delete Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Tag Delete Failed
            </Alert>
          )}
          {alertMassageUpdate && (
            <Alert status="success">
              <AlertIcon />
              Tag Update Success
            </Alert>
          )}
          {alertMassageUpdateFailed && (
            <Alert status="error">
              <AlertIcon />
              Tag Update Failed
            </Alert>
          )}

          <br />
          <Box mb={2}>
            <Flex>
              <Box mr={2}>Search: </Box> <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
              <Spacer />
            </Flex>
          </Box>

          <Box as="table" {...getTableProps()}>
            <Box bg="lightgray" as="thead">
              {headerGroups.map((headerGroup) => {
                return (
                  <Box as="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <Box p={2} minWidth="100px" fontSize="sm" as="th" {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
            {/* Apply the table body props */}
            <Box as="tbody" {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <Box as="tr" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <Box p={2} minWidth="100px" as="td" {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </Box>
                        );
                      })}
                    </Box>
                  );
                })
              }
            </Box>
          </Box>
          <br />
          <Box>
            <chakra.span>
              page {""}
              <strong>
                {pageIndex + 1} of {pageOptions.length}{" "}
              </strong>
            </chakra.span>
            <Button onClick={() => gotoPage(0)} size={"sm"} disabled={!canPreviousPage}>
              {"<<"}
            </Button>{" "}
            <Button onClick={() => previousPage()} size={"sm"} disabled={!canPreviousPage}>
              Previous
            </Button>{" "}
            <Button onClick={() => nextPage()} size={"sm"} disabled={!canNextPage}>
              Next
            </Button>{" "}
            <Button onClick={() => gotoPage(pageCount - 1)} size={"sm"} disabled={!canNextPage}>
              {">>"}
            </Button>{" "}
            <chakra.span>
              Go to page: {""}
              <Input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "50px" }}
              />
            </chakra.span>
          </Box>

          {/* MODAL START */}
          <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitUpdate();
                getAPI();
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Detail</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <ModalTag handleChange={handleOnChange} detail={collection} />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button variant="solid" colorScheme="green" type="submit">
                    {load ? (
                      <Stack direction={"row"} spacing={4} align={"center"}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack>
                    ) : (
                      "Simpan"
                    )}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
          {/* MODAL END */}
        </Box>
      </Box>
    </Fragment>
  );
}

export default Portfolio;
