import { Input,Label } from "@chakra-ui/input";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  // Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Tag,
  Box
} from "@chakra-ui/react"
import React, { Fragment, useState, useEffect } from "react";
import { useAsyncDebounce } from "react-table";


function GlobalFilter({preGlobalFilteredRows,globalFilter,setGlobalFilter}) {
  const count = preGlobalFilteredRows.length;
  const [value,setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce ((value)=>{
      setGlobalFilter(value || undefined);
  },300)

  return (
    <Box>
        
        <Input value={value ||""} onChange={(e)=>{
            setValue(e.target.value);
            onChange(e.target.value);
        }} placeholder={`Search here ${count} records...`} />
    </Box>
  );
}

export default GlobalFilter;