import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {  useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select

  } from "@chakra-ui/react"
  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";
  import { CKEditor } from '@ckeditor/ckeditor5-react';
  import Editor from 'ckeditor5-custom-build/build/ckeditor';
  import { apiPos } from "../../utils/axios";
  import Cookies from "js-cookie";
  import "./News.css";
  import {ckEditorRemoveTags,editorConfiguration} from "../../helper/CKeditorHelper"

  // console.log("plugin",Editor.builtinPlugins.map( plugin => plugin.pluginName ));

function CreatNews() {
  const onFileChange = event => { 
    // Update the state 
     setSelectedFile(event.target.files[0]);
     console.log(event.target.files[0])
  }; 


  const history = useHistory();
  const [text,setText]=useState("");
  let [load, setLoad] = useState(false);

  let initialValues = {
    title: "",
    content: ""
  };
    //state for title and content
  const [collection,setCollection]=useState(initialValues)
    // Initially, no file is selected 
    const[selectedFile,setSelectedFile]=useState(null)
  

  const handleSubmit = async () => {
    
    setLoad(true)

    await apiPos.post(`/v1/news/`,collection ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
       
      },
    })
    .then((res) => {
      setLoad(false);
      setTimeout(function(){ history.replace("/newslist") }, 2000);
     
      console.log("berhasil",res.data.data.news.content);
    })
    .catch((e) => {
      setLoad(false);
      console.log(e);
    }); 

    // setLoad(true)
    // var bodyFormData = new FormData();
    // bodyFormData.append('json', JSON.stringify(collection));
    // bodyFormData.append('image', selectedFile);

    //   console.log("body",bodyFormData)

    // // Display the key/value pairs
    // for (var pair of bodyFormData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }

    // let result = await apiPos.post(`/v1/uploadgeneral`,bodyFormData ,{
    //   headers: {
    //     Authorization: `Bearer ${Cookies.get("token")}`,
    //     AppNumber: envs.appNumber,
    //     'content-type': 'multipart/form-data'
    //   },
    // })
    // .then((res) => {
    //   setLoad(false);
    //   setTimeout(function(){ history.replace("/newslist") }, 2000);
     
    //   console.log("berhasil",res.data.data.news.content);
    // })
    // .catch((e) => {
    //   setLoad(false);
    //   console.log(e);
    // }); 
   

    
    //  console.log("ini",collection.content)
  };


  const inputOnChange = (e) => {

    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {

    //set content value every time text changes
    setCollection({
      ...collection,
      //"content": text.replace(/^([^\.]*\.[^>]*)(.*)$/g, "$1/$2")
      "content": ckEditorRemoveTags(text)
    });
  }, [text]);



  const handleOnChange = (event, editor)=>{
    
      const data = editor.getData();
      setText(data);
  
  }

  

  const{toggleColorMode}= useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Create News</Center>
            </Heading>
            </Stack>
            <br />
            <Box as={'form'}  >
              <Stack spacing={4} >
                <FormControl id="title" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="11%">
                  <FormLabel>Title</FormLabel>
                </Box>
                <Box w="89%">
                  <Input type="text"  placeholder="news title..." name="title" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="content" >
                <Stack direction={["row"]} spacing="24px">
                  <Box w="11%">
                  <FormLabel>Content</FormLabel>
                  </Box>
                  <Box w="89%">
                  <p>*upload image from url only</p>
                    <CKEditor
                        editor={ Editor }
                        config={ editorConfiguration }
                        data={text}
                        onChange={handleOnChange}
                      
                    />
                  </Box>
                </Stack>
                </FormControl>
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
              </Stack>
            </Box>            
          </Box>
      </Box>
    </Fragment>
  );
}

export default CreatNews;