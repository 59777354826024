import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Tag,
  Divider,
} from "@chakra-ui/react";
import { ImCross, ImCheckmark } from "react-icons/im";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";
import moment from "moment";

function TalentRequest() {
  const [talentRequest, setTalentRequest] = useState(null);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [load, setLoad] = useState(false);
  const [name, setName] = useState(null);

  const getAPI = () => {
    apiPos
      .get(`/v1/talent/kasirequest`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        console.log("tes", res.data.data.request);
        setTalentRequest(res.data.data.request);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const verifikasiTalent = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);

    await apiPos
      .patch(
        `/v1/talent/kasirequest`,
        {
          requestId: e,
          isApproved: true,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            AppNumber: envs.appNumber,
          },
        }
      )
      .then((res) => {
        console.log(res);

        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  const getUser = (e) => {
    apiPos
      .get(`/v1/user/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        console.log("tes", res);
        setName(res.data.data.user.name);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const user = (e) => {
    // getUser(e);

    return `tes${name}`;
  };

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>List Talent Request</Center>
            </Heading>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Talent Approval Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Talent Approval Failed
            </Alert>
          )}
          <Fragment>
            <br />
            <Table variant="striped" colorScheme="blackAlpha" size="sm" border="1px" borderColor="gray.200">
              <Thead>
                <Tr>
                  <Th>Nama</Th>
                  <Th>Email</Th>
                  <Th>Bidang Jasa Yang Telah Disetujui</Th>
                  <Th>Level Kemitraan</Th>
                  <Th>Portfolio Link</Th>
                  <Th>Dibuat</Th>
                  <Th>Update</Th>
                  <Th>Bidang Jasa Yang Diajukan</Th>
                  <Th>Approve</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {talentRequest &&
                  talentRequest.map((item) => (
                    <Tr key={item.id}>
                      <Td>{item.user.name}</Td>
                      <Td>{item.user.email}</Td>
                      <Td>
                        {item.user.bidangjasa.map((item, i) => (
                          <Tag key={i} variant="solid" colorScheme="teal">
                            {item.kasi.name}
                          </Tag>
                        ))}
                      </Td>
                      <Td>{item.user.level.levelNumber}</Td>
                      <Td>{item.portfolioLink}</Td>
                      <Td>{moment(item.createdAt).utc().format("LLLL")}</Td>
                      <Td>{moment(item.updatedAt).utc().format("LLLL")}</Td>
                      <Td>
                        {item.item.map((item, i) => {
                          return (
                            <Tag key={i} variant="solid" colorScheme="yellow">
                              {item.kasi.name}
                            </Tag>
                          );
                        })}
                      </Td>
                      <Td>{item.isApproved ? <ImCheckmark /> : <ImCross />}</Td>
                      <Td>
                        {load ? (
                          <Button isLoading loadingText="Wait..."></Button>
                        ) : item.status ? (
                          "Approved"
                        ) : (
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              if (window.confirm(`Anda Yakin verifikasi Mitra ${item.user.name}`)) verifikasiTalent(item.id);
                            }}
                          >
                            Approve
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default TalentRequest;
