// import {
//   GET_PRODUCTORDERS_PENDING,
//   GET_PRODUCTORDERS_FAIL,
//   GET_PRODUCTORDERS_SUCCESS,
// } from "../constants";

const initialState = {
    user: null,
    loading: true,
    error: null,
  };
  
  const userReducer = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case "set":
        return { ...state, ...rest };
      // case GET_PRODUCTORDERS_PENDING:
      //   return { ...state, loading: true };
      // case GET_PRODUCTORDERS_FAIL:
      //   return { ...state, error: rest.error };
      // case GET_PRODUCTORDERS_SUCCESS:
      //   return { ...state, productorders: rest.productorders };
      default:
        return state;
    }
  };
  
  export default userReducer;