import React, { Fragment, useState, useEffect } from "react";
import {  useHistory } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Flex,
  Spacer
} from "@chakra-ui/react"
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";


function NewsList() {
  const history = useHistory();
  const [news,setNews]=useState([])
  const [load, setLoad] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const getAPI=()=>{
    apiPos
    .get(`/v1/news`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
      },
    })
    .then((res) => {
      setNews(res.data.data.news);
     
    })
    .catch((e) => {
      console.log(e);
    });
  }

  const deleteNews = async (e) => {
    setalertMassage(false)
    setalertMassageFailed(false)
    setLoad(true)
   
    await apiPos
    .delete(`/v1/news/${e}` ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
      },
    })
    .then((res) => {

      
        setalertMassage(true)

    })
    .catch((e) => {
      console.error(e.response.data);
      setalertMassageFailed(true)
      

    });
    setLoad(false);
    setTimeout(function(){ setalertMassage(false) }, 3000);
    setTimeout(function(){ setalertMassageFailed(false) }, 3000)
    getAPI()
  } 

    //api to show table all user
    useEffect(() => {
      // request to API
      getAPI()
    }, []);

  return (
    <Fragment> 
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
        <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>List News</Center>
            </Heading>
            <Flex>
              <Spacer />
              <Box >
              <Button  colorScheme="blue" size="md" onClick={()=>history.replace("/createnews")}>+ Add</Button>
              </Box>
            </Flex>
            </Stack>
            {alertMassage &&      
                <Alert status="success">
                <AlertIcon />
                Delete news Success
              </Alert>}
            {alertMassageFailed &&      
                <Alert status="error">
                <AlertIcon />
                Delete news Failed
              </Alert>} 
            <Fragment>
              <br />
            <Table  variant="striped" colorScheme="blackAlpha" size="sm" border="1px" borderColor="gray.200" >
              <Thead >
                <Tr >
                  <Th>ID</Th>
                  <Th>Title News</Th>
                  <Th>Content</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {news && news.map((item)=>(
                  <Tr key={item.id}>
                    <Td >{item.id}</Td>
                    <Td >{item.title}</Td>
                    <Td >{item.content.substring(0,100)}</Td>
                    <Td>
                    {load ?
                      <Button 
                      isLoading
                      loadingText="Wait..." >
                      </Button>
                    :<Button  colorScheme="red" size="sm" onClick={() => {if (window.confirm('Are you sure you wish to delete this item?')) deleteNews(item.id)}}>Delete</Button>}
                    </Td>
                  </Tr>
                ))
                }
              </Tbody>
            </Table>
            </Fragment> 
        </Box>
      </Box>
    </Fragment>
  );
}

export default NewsList;