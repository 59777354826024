import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Center,
  Spinner,
  Text,
  Input
} from "@chakra-ui/react"
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import { Redirect} from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Stack,
  Checkbox,
  Select
} from "@chakra-ui/react"
import { boolean } from "yup";
import { Image } from "@chakra-ui/react"
import {  useHistory } from "react-router-dom";


const ModalTag = ({handleChange, detail}) => {
  const [kasi,setKasi]=useState([]);


  // handle change form
  const handleOnChange = (e) => {

    // kirim state ke parent
    handleChange(e);
  };


//api to show selection all kasi services
const getAPI=()=>{
  apiPos
  .get(`/v1/kasi`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      AppNumber: envs.appNumber,
    },
  })
  .then((res) => {
    setKasi(res.data.data.kasi);

  })
  .catch((e) => {
    console.log(e);
  });
}


useEffect(() => {
// request to API
getAPI()
}, []);


  return (
    <Fragment>
      {/* {console.log("ini id",detail)} */}
              <Stack spacing={4} >
                <FormControl id="name" >
                    <Stack direction={["row"]} spacing="24px">
                        <Box w="25%">
                            <FormLabel>Tag Name</FormLabel>
                        </Box>
                        <Box w="75%">
                            <Input type="text"  placeholder="...." name="name" onChange={handleOnChange} value={detail.name || ''}/>
                        </Box>
                    </Stack>
                </FormControl>
                <FormControl id="kasiId" >
                    <Stack direction={["row"]} spacing="24px">
                        <Box w="25%">
                            <FormLabel>Kasi Service</FormLabel>
                        </Box>
                        <Box w="75%">
                        <Select name="kasiId" placeholder="Select Kasi Services" onChange={handleOnChange} value={detail.kasiId || ''}>
                          {kasi && kasi.map((item,index)=>(
                            <option key={index} value={item.id} >{item.name}</option>
                           
                          ))}
                        </Select>
                        </Box>
                    </Stack>
                </FormControl>
                <br />         
              </Stack>
    </Fragment>
  );
}

export default ModalTag;