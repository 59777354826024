import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Flex,
  Spacer,
  Link,
} from "@chakra-ui/react";
import moment from "moment";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";

function PageMapList() {
  // const [aObjectKosong,setAObjectKosong]=useState([])
  const history = useHistory();
  const [pagemap, setPagemap] = useState([]);
  const [load, setLoad] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const getAPI = () => {
    apiPos
      .get(`/v1/pagemap`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setPagemap(res.data.data.pageMaps);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deletePageMap = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);

    await apiPos
      .delete(`/v1/pagemap/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        {console.log(pagemap)}
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Page Map List</Center>
            </Heading>
            <Flex>
              <Spacer />
              <Box>
                <Button colorScheme="blue" size="md" onClick={() => history.replace("/createpagemap")}>
                  + Add
                </Button>
              </Box>
            </Flex>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Delete Page Map Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Delete Page Map Failed
            </Alert>
          )}
          <Fragment>
            <br />
            <Table variant="striped" colorScheme="blackAlpha" size="sm" border="1px" borderColor="gray.200">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Page Name</Th>
                  <Th>Page Param (color)</Th>
                  <Th>Page Param (kasi Id)</Th>
                  <Th>Update At</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {pagemap &&
                  pagemap.map((item) => {
                    console.log(item.pageParam);
                    let pageParam = JSON.parse(item.pageParam);
                    console.log(JSON.stringify(item.pageParam));
                    return (
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>
                        <Td>{item.pageName}</Td>
                        <Td>{pageParam.color}</Td>
                        <Td>{pageParam.kasiId}</Td>
                        <Td>{moment(item.updatedAt).utc().format("LLLL")}</Td>
                        <Td>
                          {load ? (
                            <Button isLoading loadingText="Wait..."></Button>
                          ) : (
                            <Button
                              colorScheme="red"
                              size="sm"
                              onClick={() => {
                                if (window.confirm("Are you sure you wish to delete this item?")) deletePageMap(item.id);
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default PageMapList;
