import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Flex,
  Grid,
  GridItem,
  Spacer,
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Badge,
  Center,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import envs from "../../../envs";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../img/LogoKasisolusi.png";
import { apiPos } from "../../../utils/axios";
import Carousel from "react-elastic-carousel";
import Icon from "../../../img/Icon.png";
import { AiFillStar } from "react-icons/ai";
import moneyFormat from "../../../helper/helper";

export default function Live() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const LiveId = searchParams.get("liveId");
  const slug = searchParams.get("slug");

  const [livedata, setLiveData] = useState(null);
  const [product, setProduct] = useState([]);

  const redirectStore = () => {
    // and viewed on an Android Phone...
    if (navigator.userAgent.match(/Android/)) {
      setTimeout(function () {
        window.location.replace("http://play.google.com/store/apps/details?id=com.kasisolusi");
      }, 5000);
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      setTimeout(function () {
        window.location.replace("http://play.google.com/store/apps/details?id=com.kasisolusi");
      }, 5000);
    } else {
    }
  };

  const redirectApp = () => {
    // and viewed on an Android Phone...
    if (navigator.userAgent.match(/Android/)) {
      setTimeout(function () {
        window.location.replace("kasisolusi://app/livestream");
      }, 2000);
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      setTimeout(function () {
        window.location.replace("kasisolusi://app/livestream");
      }, 2000);
    } else {
    }
  };

  const redirectProduct = (prod) => {
    // and viewed on an Android Phone...
    if (navigator.userAgent.match(/Android/)) {
      setTimeout(function () {
        window.location.replace(`kasisolusi://app/product/${slug}`);
      }, 2000);
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      setTimeout(function () {
        window.location.replace(`kasisolusi://app/product/${slug}`);
      }, 2000);
    } else {
    }
  };

  const getAPI = (id) => {
    //get ticket
    apiPos
      .get(`/v1/livestream/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        // console.log("livestream", res);
        setLiveData(res.data.data.livestreams);
        setProduct(res.data.data.livestreams.livestreamuserkasi);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAPIID = () => {
    //get ticket
    apiPos
      .get(`/v1/livestream?slug=${slug}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        // console.log("lapi id", res);
        getAPI(res.data.data.livestreams[0].id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAPIID();
  }, []);

  const { toggleColorMode } = useColorMode();
  const formBackground = useColorModeValue("gray.100", "grey.700");
  return (
    <Fragment>
      <Flex minH={"100vh"} align={"center"} justify={"center"} bg={formBackground}>
        <Box spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Box mb={4}>
              <Image src={Logo} alt="Logo Kasisolusi" />
            </Box>
          </Stack>
          {/* <Box mb={4}>
            <Box p={5} shadow="md" borderWidth="5px" flex="1" borderRadius="xl" bg={useColorModeValue("gray.100", "gray.700")}> */}
          {livedata && (
            // <Grid templateColumns="repeat(5, 1fr)">
            //   <GridItem colSpan={2} h="10">
            //     Nama:
            //   </GridItem>
            //   <GridItem colStart={4} colEnd={6} h="10">
            //     {livedata.user.name}
            //   </GridItem>
            //   <GridItem colSpan={2} h="10">
            //     Judul
            //   </GridItem>
            //   <GridItem colStart={4} colEnd={6} h="10">
            //     {livedata.name}
            //   </GridItem>
            //   <GridItem colSpan={2} h="10">
            //     Deskripsi:
            //   </GridItem>
            //   <GridItem colStart={4} colEnd={6} h="10">
            //     {livedata.description}
            //   </GridItem>
            // </Grid>
            <Center py={12}>
              <Box role={"group"} p={6} maxW={"330px"} w={"full"} boxShadow={"2xl"} rounded={"lg"} pos={"relative"} zIndex={1} bg="gray.300">
                <Box
                  rounded={"lg"}
                  pos={"relative"}
                  height={"230px"}
                  _after={{
                    transition: "all .3s ease",
                    content: '""',
                    w: "full",
                    h: "full",
                    pos: "absolute",
                    top: 5,
                    left: 0,
                    backgroundImage: `url(${envs.imageUrl}/${livedata.thumbnail})`,
                    filter: "blur(15px)",
                    zIndex: -1,
                  }}
                  _groupHover={{
                    _after: {
                      filter: "blur(20px)",
                    },
                  }}
                >
                  <Image rounded={"lg"} height={230} width={282} objectFit={"cover"} src={`${envs.imageUrl}/${livedata.thumbnail}`} />
                </Box>
                <Stack pt={10} align={"center"}>
                  <Text color={"gray.500"} fontSize={"sm"} textTransform={"uppercase"} fontWeight={800}>
                    {livedata.user.name}
                  </Text>
                  <HStack direction={"row"} align={"center"}>
                    <Text fontWeight={500} fontSize={"2xl"}>
                      {livedata.name}
                    </Text>
                  </HStack>
                  <Stack direction={"row"} align={"center"}>
                    <Center>
                      <Text fontWeight={400} fontSize={"md"}>
                        {livedata.description}
                      </Text>
                    </Center>
                  </Stack>
                </Stack>
              </Box>
            </Center>
          )}
          <Heading as="h4" size="md" mb={6}>
            PRODUCT
          </Heading>

          <Carousel>
            {product &&
              product.map((item, i) => {
                console.log("ini produk", item);
                return (
                  <Fragment key={i}>
                    <Stack direction={"row"} align={"center"}>
                      {/* <Stack direction={"row"} align={"center"} key={i}>
                    <Text fontWeight={800} fontSize={"xl"}>
                      PRODUCT:{item.userkasi.userkasiName}
                    </Text>
                  </Stack> */}
                      <Box w={[, , 320]} p={[, , 2]} h={[, , 400]} borderWidth="1px" borderRadius="lg" overflow="hidden" bg="gray.300" key={i}>
                        {" "}
                        {item.userkasi.userkasiImage && <Image src={`${envs.imageUrl}/${item.userkasi.userkasiImage}`} maxH={[, , 400]} maxW={[, , 300]} alt="image product" />}
                        <Box p="2">
                          <Center>
                            <Box mt="1" fontWeight="semibold" as="h5" lineHeight="tight">
                              {item.userkasi.userkasiName}
                            </Box>
                          </Center>
                          <Text mt="2" fontSize="sm">
                            {item.userkasi.userkasiDescription}
                          </Text>

                          <Text mt="2" fontSize="sm">
                            {moneyFormat(item.userkasi.rate)}
                          </Text>

                          <Box display="flex" direction="row" mt="2" alignItems="center">
                            <AiFillStar color="teal.500" />
                            &nbsp;&nbsp; {item.userkasi.ratingTotal}
                            <Spacer />
                            <Button onClick={() => redirectProduct(item.userkasi.slug)} colorScheme="linkedin" size="xs">
                              OPEN <ExternalLinkIcon ml="2px" />
                            </Button>
                            <Spacer />
                            <Box as="span" color="gray.600" fontSize="sm">
                              {item.userkasi.ratingCount} reviews
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Fragment>
                );
              })}
          </Carousel>

          <Box mt={6}>
            <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={2} mb={4}>
              <Box align={"center"} bg={useColorModeValue("white", "gray.700")}>
                <Button onClick={redirectApp} w="full" colorScheme="linkedin">
                  Buka Kasisolusi
                </Button>
              </Box>
            </Box>
            <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={2}>
              <Box align={"center"} bg={useColorModeValue("white", "gray.700")}>
                <Button onClick={redirectStore} w="full" colorScheme="linkedin">
                  Download Kasisolusi
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Fragment>
  );
}
