import React, { ReactNode } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";
import { FiHome, FiTrendingUp, FiCompass, FiStar, FiSettings, FiMenu, FiBell, FiChevronDown } from "react-icons/fi";
import { FaUserAlt, FaMoneyBill, FaTicketAlt, FaUserClock, FaSearch } from "react-icons/fa";
import { RiSlideshowFill, RiTodoFill, RiCustomerService2Fill } from "react-icons/ri";
import { GiSkills, GiThreeFriends } from "react-icons/gi";
import { BiTask } from "react-icons/bi";
import { IconType } from "react-icons";
import { ImNewspaper } from "react-icons/im";
import { AiFillYoutube, AiOutlineForm, AiFillTag, AiFillProject, AiFillSetting } from "react-icons/ai";
import { SiGoogleclassroom, SiPagekit } from "react-icons/si";
import { ReactText } from "react";
import Cookies from "js-cookie";
import { Route, Redirect, useHistory } from "react-router-dom";
import logo from "../../img/LogoKasisolusi.png";

// interface LinkItemProps {
//   name: string;
//   icon: IconType;
// }
const LinkItemsAdmin = [
  // { name: 'Home', icon: FiTrendingUp },
  // { name: 'Dashboard', icon: FiHome, href:"/dashboard" },
  { name: "All Users", icon: FaUserAlt, href: "/users" },
  { name: "Users Not Approved", icon: FaUserClock, href: "/usersnotapprove" },
  { name: "Talent Request", icon: GiSkills, href: "/talentrequest" },
  { name: "Count Mitra", icon: GiThreeFriends, href: "/countmitra" },
  // { name: "Submit Task", icon: BiTask, href: "/submittask" },
  // { name: "Create Class", icon: SiGoogleclassroom, href: "/createclass" },
  { name: "Slider", icon: RiSlideshowFill, href: "/sliderlist" },
  // { name: 'News', icon: ImNewspaper, href:"/newslist" },
  // { name: 'Youtube', icon: AiFillYoutube, href:"/youtubelist" },
  { name: "Voucher", icon: FaTicketAlt, href: "/voucherlist" },
  { name: "Page Map", icon: SiPagekit, href: "/pagemap" },
  { name: "Kasi", icon: AiFillSetting, href: "/kasilist" },
  { name: "Form Field", icon: AiOutlineForm, href: "/formfield" },
  { name: "Term & Condition", icon: RiTodoFill, href: "/termcondition" },
  { name: "Project", icon: AiFillProject, href: "/project" },
  { name: "Tag", icon: AiFillTag, href: "/tag" },
  { name: "Pengaduan", icon: RiCustomerService2Fill, href: "/ticketlist" },
  { name: "Submit Project", icon: FiSettings, href: "/submission" },
  { name: "Search Suggestion", icon: FaSearch, href: "/searchlist" },
  // { name: 'Settings', icon: FiSettings },
];

const LinkItems = [
  { name: "Portfolio", icon: RiCustomerService2Fill, href: "/portfolio" },
  { name: "Submit Project", icon: FiSettings, href: "/submission" },
  // { name: 'Settings', icon: FiSettings },
];

export default function SidebarWithHeader({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
      <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      {/* <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box> */}
    </>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box transition="3s ease" bg={useColorModeValue("white", "gray.900")} borderRight="1px" borderRightColor={useColorModeValue("gray.200", "gray.700")} w={{ base: "full", md: 60 }} pos="fixed" h="full" {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={logo} alt="Logo Kasisolusi" />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {Cookies.get("role") == "admin"
        ? LinkItemsAdmin.map((link) => (
            <NavItem key={link.name} icon={link.icon} href={link.href}>
              {link.name}
            </NavItem>
          ))
        : LinkItems.map((link) => (
            <NavItem key={link.name} icon={link.icon} href={link.href}>
              {link.name}
            </NavItem>
          ))}
    </Box>
  );
};

const NavItem = ({ icon, children, href, ...rest }) => {
  return (
    <Link href={href} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          //bg: 'cyan.400',
          bg: "#3D84F0",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const history = useHistory();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton display={{ base: "flex", md: "none" }} onClick={onOpen} variant="outline" aria-label="open menu" icon={<FiMenu />} />

      <Text display={{ base: "flex", md: "none" }} fontSize="2xl" fontFamily="monospace" fontWeight="bold">
        {/* Logo */}
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar
                  size={"sm"}
                  // src={
                  //   'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  // }
                />
                <VStack display={{ base: "none", md: "flex" }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm">{Cookies.get("user")}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {Cookies.get("role")}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={useColorModeValue("white", "gray.900")} borderColor={useColorModeValue("gray.200", "gray.700")}>
              {/* <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider /> */}
              <MenuItem
                onClick={() => {
                  Cookies.remove("token");
                  Cookies.remove("user");
                  history.replace("/login");
                }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
