import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue, Stack, Heading, HStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  StarIcon,
  Center,
  Formik,
  Form,
  Field,
  Button,
  Flex,
  Text,
  Link,
  Checkbox,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Select,
} from "@chakra-ui/react";

import * as Yup from "yup";
import axios from "axios";
import envs from "../../envs";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

function CreatSlider() {
  const [value, setValue] = useState(null);
  const [loginFail, setLoginFail] = useState(null);
  const history = useHistory();

  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState(null);

  let initialValues = {
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    pageTo: "",
    pageParam: "",
  };
  //state for title and description
  const [collection, setCollection] = useState(initialValues);

  let [load, setLoad] = useState(false);

  const handleSubmit = async () => {
    setLoad(true);
    var bodyFormData = new FormData();
    bodyFormData.append("json", JSON.stringify(collection));
    bodyFormData.append("image", selectedFile);

    console.log("body", bodyFormData);

    // Display the key/value pairs
    for (var pair of bodyFormData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    await apiPos
      .post(`/v1/slider/`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoad(false);
        setTimeout(function () {
          history.replace("/sliderlist");
        }, 1000);
        console.log(res);
      })
      .catch((e) => {
        setLoad(false);
        console.log(e);
      });
    console.log(collection);
  };

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const onChangeDate = (e) => {
    setValue(e);
  };

  const inputOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log("ini date", value);
    if (value != null) {
      //setiap value date berubah maka set value startDate dan endDate
      setCollection({
        ...collection,
        startDate: value[0],
        endDate: value[1],
      });
    }
  }, [value]);

  useEffect(() => {
    // console.log("ini collection",collection)
  }, [collection]);

  const { toggleColorMode } = useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Create Slider</Center>
            </Heading>
          </Stack>
          <br />
          <Box as={"form"}>
            <Stack spacing={4}>
              <FormControl id="title">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Title</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="Input Title..." name="title" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="description">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Description</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="Input Slider Description..." name="description" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="date">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Date</FormLabel>
                  </Box>
                  <Box w="75%">
                    <DateRangePicker onChange={onChangeDate} value={value} format={"y-MM-dd"} dayPlaceholder="dd" yearPlaceholder="yyyy" monthPlaceholder="mm" minDate={new Date()} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="slider">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Upload Slider</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input variant="filled" type="file" size="sm" onChange={onFileChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="pageParam">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Page Param</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="Input Page Param..." name="pageParam" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="pageTo">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Page To</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="Input Page To..." name="pageTo" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <br />
              <Stack spacing={10}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  {load ? (
                    <Stack direction={"row"} spacing={4} align={"center"}>
                      <Spinner />
                      <Text>Loading...</Text>
                    </Stack>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreatSlider;
