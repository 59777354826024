import axios from 'axios';
import React,{Component,Fragment,useState} from 'react'; 
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    useColorModeValue,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    CloseButton,
    useDisclosure,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Heading,
    Stack,
    Spinner,
    Text,
    Center,
    Input
  } from "@chakra-ui/react"
  function UploadTask() { 
    // Initially, no file is selected 
    const[selectedFile,setSelectedFile]=useState(null)
    // state = { 
  
      
    //   selectedFile: null
    // }; 
     
    // On file select (from the pop up) 
    const onFileChange = event => { 
      // Update the state 
      //this.setState({ selectedFile: event.target.files[0] }); 
      setSelectedFile(event.target.files[0]);
    }; 
     
    // On file upload (click the upload button) 
    const onFileUpload = () => { 
      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "myFile", 
        selectedFile, 
        selectedFile.name 
      ); 
     
      // Details of the uploaded file 
      console.log(selectedFile); 
     
      // Request made to the backend api 
      // Send formData object 
      axios.post("api/uploadfile", formData); 
    }; 
     
    // File content to be displayed after 
    // file upload is complete 
    const fileData = () => { 
      if (selectedFile) { 
          
        return ( 
          <Box> 
            <Text>File Details:</Text> 
            <Text>File Name: {selectedFile.name}</Text> 
            <Text>File Type: {selectedFile.type}</Text> 
            <Text> 
              Last Modified:{" "} 
              {selectedFile.lastModifiedDate.toDateString()} 
            </Text> 
          </Box> 
        ); 
      } else { 
        return ( 
          <Box> 
            <br /> 
            <Text>Choose before Pressing the Upload button</Text> 
          </Box> 
        ); 
      } 
    }; 
     
    return ( 
        <Fragment> 
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
        <Box       
          p={5}
          shadow="md"
          borderWidth="1px"
          flex="1"
          borderRadius="md"> 
            <Heading fontSize="xl" mb={4}>Upload Your Project Here</Heading> 
            <Stack >
                <Input variant="filled" type="file"  size="sm" onChange={onFileChange} /> 
                {fileData()} 
                <Button onClick={onFileUpload} colorScheme="teal" variant="solid">Upload!</Button>
            </Stack> 
        </Box> 
        </Box>
    </Fragment>
      ); 
    
  } 
  
  export default UploadTask; 


// import React, { useState } from "react";

// const UploadTask = () => {
//   const [name, setName] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);
//   return (
//     <div className="App">
//       <form>
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//         />

//         <input
//           type="file"
//           value={selectedFile}
//           onChange={(e) => setSelectedFile(e.target.files[0])}
//         />
//       </form>
//     </div>
//   );
// };
// export default UploadTask;