import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {  useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select
  } from "@chakra-ui/react"
  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";
  import { apiPos } from "../../utils/axios";
  import Cookies from "js-cookie";


function CreatTag() {
  const history = useHistory();
  const [kasi,setKasi]=useState([]);

  let initialValues = {
    name: "",
    kasiId:null,
  };
    //state for title and content
  const [collection,setCollection]=useState(initialValues);

  const [load, setLoad] = useState(false);


  const handleSubmit = async () => {
    setLoad(true)
    console.log(collection)
    await apiPos.post(`/v1/kasitag`,collection ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
      },
    })
    .then((res) => {
      setLoad(false);
      history.replace("/tag")
     
      console.log(res)
    })
    .catch((e) => {
      setLoad(false);
      console.log(e);
    }); 
    setCollection(initialValues);

  };


//api to show selection all kasi services
  const getAPI=()=>{
    apiPos
    .get(`/v1/kasi`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
      },
    })
    .then((res) => {
      setKasi(res.data.data.kasi);
     console.log(res)
  
    })
    .catch((e) => {
      console.log(e);
    });
  }


useEffect(() => {
  // request to API
  getAPI()
}, []);


const inputOnChange = (e) => {
  setCollection({
    ...collection,
    [e.target.name]: e.target.value,
  });
};



  const{toggleColorMode}= useColorMode();

  return (
    <Fragment>{console.log("ini collec",collection)}
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Create Tag</Center>
            </Heading>
            </Stack>
            <br />
            <Box as={'form'}  >
              <Stack spacing={4} >
                <FormControl id="name" >
                    <Stack direction={["row"]} spacing="24px">
                        <Box w="25%">
                            <FormLabel>Tag Name</FormLabel>
                        </Box>
                        <Box w="75%">
                            <Input type="text"  placeholder="...." name="name" onChange={inputOnChange}/>
                        </Box>
                    </Stack>
                </FormControl>
                <FormControl id="kasiId" >
                    <Stack direction={["row"]} spacing="24px">
                        <Box w="25%">
                            <FormLabel>Kasi Service</FormLabel>
                        </Box>
                        <Box w="75%">
                        <Select name="kasiId" placeholder="Select Kasi Services" onChange={inputOnChange}>
                          {kasi && kasi.map((item,index)=>(
                            <option key={index} value={item.id} >{item.name}</option>
                           
                          ))}
                        </Select>
                        </Box>
                    </Stack>
                </FormControl>
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
              </Stack>
            </Box>            
          </Box>
      </Box>
    </Fragment>
  );
}

export default CreatTag;