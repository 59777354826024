import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {  useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select,
    InputGroup,
    InputRightAddon,
    InputLeftAddon
  } from "@chakra-ui/react"

  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";

  import { apiPos } from "../../utils/axios";
  import Cookies from "js-cookie";
  import DateRangePicker from '@wojtekmaj/react-daterange-picker';

function CreatVoucher() {
  const [value, setValue] = useState([new Date(), new Date()]);
  const history = useHistory();
  const [selectState, setSelectState] = useState(false);


    // Initially, no file is selected 
    const[selectedFile,setSelectedFile]=useState(null)

  let initialValues = {
    name: "",
    minQty: "",
    minPrice:"",
    voucherCode:"",
    discount:null,
    maxDiscount:null,
    cashback:null,
    maxCashback:null,
    eligableProduct:"",
    eligableProductId:"",
  };
    //state for title and description
  const [collection,setCollection]=useState(initialValues)

  let [load, setLoad] = useState(false);


  const handleSubmit = async () => {
 
    setLoad(true)
  
    await apiPos.post(`/v1/voucher/`,collection ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
      },
    })
    .then((res) => {
      setLoad(false);
      setTimeout(function(){ history.replace("/voucherlist")}, 1000);
      console.log(res);
      
    })
    .catch((e) => {
      setLoad(false);
      console.log(e);
    }); 
 

  };


     

//setvalue untuk date
  const onChangeDate = (e) =>{
    setValue(e)
  }


  const inputOnChange = (e) => {

    setCollection({
      ...collection,
      [e.target.name]: e.target.value
    });

  };


  useEffect(() => {
      console.log("ini date",value)
      //setiap value date berubah maka set value startDate dan endDate
      setCollection({
        ...collection,
        startDate:value[0],
        endDate:value[1],
      });
  }, [value]);

  useEffect(() => {
    console.log("ini collection",collection)

}, [collection]);


const inputOnChangeDisc =(e)=>{
  setCollection({
    ...collection,
    [e.target.name]: e.target.value/100
  });
}

const changeSelect =()=>{
  setSelectState(!selectState)
  // if(selectState)
  setCollection({
    ...collection,
    discount:null,
    maxDiscount:null,
    cashback:null,
    maxCashback:null,
  });
}

  const{toggleColorMode}= useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Create Voucher</Center>
            </Heading>
            </Stack>
            <br />
            <Box as={'form'}  >
              <Stack spacing={4} >
                <FormControl id="name" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Name</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="Nama Voucher..." name="name" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="minQty" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Min Quantity</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="Minimal Quantity..." name="minQty" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="minPrice" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Min Price</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="Minimal Price..." name="minPrice" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="voucherCode" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Voucher Code</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="Voucher code..." name="voucherCode" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="voucherCode" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">

                </Box>
                <Box w="75%">
                <Select placeholder="Select Discount/Cashback" onChange={changeSelect} value={selectState}>
                    <option value="true">Discount</option>
                    <option value="false">Cashback</option>
                  </Select>
                </Box>
                </Stack>
                </FormControl>
                
                {(selectState) ? <Fragment><FormControl id="discount" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Discount</FormLabel>
                </Box>
                <Box w="75%">
                  <InputGroup size="sm">
                    <Input type="number" placeholder="Discount (%)..." name="discount" onChange={inputOnChangeDisc}/>
                    <InputRightAddon children="%" />
                  </InputGroup>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="maxDiscount" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Max Discount</FormLabel>
                </Box>
                <Box w="75%">
                <InputGroup size="sm">
                    <Input type="number" placeholder="Maximum Discount (%)..." name="maxDiscount" onChange={inputOnChangeDisc}/>
                    <InputRightAddon children="%" />
                  </InputGroup>
                </Box>
                </Stack>
                </FormControl>
                </Fragment> : 
                <Fragment>
                <FormControl id="cashback" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Cashback</FormLabel>
                </Box>
                <Box w="75%">
                  
                  <InputGroup size="sm">
                    <InputLeftAddon children="Rp. " />
                    <Input type="number"  placeholder="Cashback (nominal)..." name="cashback" onChange={inputOnChange}/>
                  </InputGroup>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="maxCashback" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Max Cashback</FormLabel>
                </Box>
                <Box w="75%">
                <InputGroup size="sm">
                    <InputLeftAddon children="Rp. " />
                    <Input type="number"  placeholder="Cashback (nominal)..." name="maxCashback" onChange={inputOnChange}/>
                  </InputGroup>
                </Box>
                </Stack>
                </FormControl></Fragment>}
                <FormControl id="eligableProduct" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Eligable Product</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="Eligable Product..." name="eligableProduct" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="eligableProductId" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Eligable Product ID</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="ID Eligable Product..." name="eligableProductId" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="date"  >
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                  <FormLabel>Date</FormLabel>
                  </Box>
                  <Box w="75%">
                  <DateRangePicker 
                   onChange={onChangeDate}
                   value={value}
                   format={"y-MM-dd"}
                   dayPlaceholder="dd"
                   yearPlaceholder="yyyy"
                   monthPlaceholder="mm"
                   minDate={new Date()}
                   />
                  </Box>
                </Stack>
                </FormControl>
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Box>
      </Box>
    </Fragment>
  );
}

export default CreatVoucher;