import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Flex,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";
import moment from "moment";

function VoucherList() {
  const history = useHistory();
  const [voucher, setVoucher] = useState([]);
  const [load, setLoad] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const getAPI = () => {
    apiPos
      .get(`/v1/voucher?period=all`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setVoucher(res.data.data.vouchers);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteVoucher = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);

    await apiPos
      .delete(`/v1/voucher/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>List Voucher</Center>
            </Heading>
            <Flex>
              <Spacer />
              <Box>
                <Button colorScheme="blue" size="md" onClick={() => history.replace("/createvoucher")}>
                  + Add
                </Button>
              </Box>
            </Flex>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Delete Voucher Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Delete Voucher Failed
            </Alert>
          )}

          <br />
          <Table variant="striped" colorScheme="blackAlpha" size="sm" border="1px" borderColor="gray.200">
            <Thead>
              <Tr>
                {/* <Th>ID</Th> */}
                <Th>Name</Th>
                <Th>Min Qty</Th>
                <Th>Min Price</Th>
                <Th>Voucher Code</Th>
                <Th>Discount</Th>
                <Th>maxDiscount</Th>
                {/* <Th>Cashback</Th>
                  <Th>maxCashback</Th> */}
                <Th>eligableProduct</Th>
                {/* <Th>eligableProductId</Th> */}
                <Th>startDate</Th>
                <Th>endDate</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {voucher &&
                voucher.map((item) => (
                  <Tr key={item.id}>
                    {/* <Td >{item.id}</Td> */}
                    <Td>{item.name}</Td>
                    <Td>{item.minQty}</Td>
                    <Td>{item.minPrice}</Td>
                    <Td>{item.voucherCode}</Td>
                    <Td>{item.discount * 100}%</Td>
                    <Td>{item.maxDiscount * 100}%</Td>
                    {/* <Td>{item.cashback}</Td>
                    <Td>{item.maxCashback}</Td> */}
                    <Td>{item.eligableProduct}</Td>
                    {/* <Td>{item.eligableProductId}</Td> */}
                    <Td>{moment(item.startDate).utc().format("LLLL")}</Td>
                    <Td>{moment(item.endDate).utc().format("LLLL")}</Td>
                    <Td>
                      {load ? (
                        <Button isLoading loadingText="Wait..."></Button>
                      ) : (
                        <Button
                          colorScheme="red"
                          size="sm"
                          onClick={() => {
                            if (window.confirm("Are you sure you wish to delete this voucher?")) deleteVoucher(item.id);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Fragment>
  );
}

export default VoucherList;
