import React, { Fragment, useState, useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Center,
  Tag,
} from "@chakra-ui/react";
import { ImCross, ImCheckmark } from "react-icons/im";
import { apiPos } from "../../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../../envs";
import { Redirect } from "react-router-dom";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Stack, Checkbox } from "@chakra-ui/react";
import { boolean } from "yup";
import { Image } from "@chakra-ui/react";
import moment from "moment";

const ModalUser = ({ handleChange, detail }) => {
  const [user, setUser] = useState(null);

  // handle change form
  const handleOnChange = (e) => {
    // kirim state ke parent
    handleChange(e);
  };

  return (
    <Fragment>
      {detail && detail.partnerregistration.length > 0 ? (
        <Table variant="striped" colorScheme="blue" size="sm">
          {console.log("ini det", detail)}
          <Tbody>
            <Tr>
              <Td>Name</Td>
              <Td>{detail.name}</Td>
            </Tr>
            <Tr>
              <Td>User ID</Td>
              <Td>{detail.partnerregistration[0].userId}</Td>
            </Tr>
            <Tr>
              <Td>Dibuat</Td>
              <Td>{moment(detail.partnerregistration[0].createdAt).utc().format("LLLL")}</Td>
            </Tr>
            <Tr>
              <Td>Address</Td>
              <Td>{detail.partnerregistration[0].address}</Td>
            </Tr>
            {/* <Tr>
              <Td>Email</Td>
              <Td>{detail.email}</Td>
            </Tr>
            <Tr>
              <Td>Phone Number</Td>
              <Td>{detail.phoneNumber}</Td>
            </Tr> */}
            <Tr>
              <Td>NIK</Td>
              <Td>{detail.partnerregistration[0].nik}</Td>
              {console.log(detail)}
            </Tr>
            <Tr>
              <Td>NPWP</Td>
              <Td>{detail.partnerregistration[0].npwp}</Td>
            </Tr>
            <Tr>
              <Td>Portfolio Link</Td>
              <Td>{detail.partnerregistration[0].portfolioLink}</Td>
            </Tr>
            <Tr>
              <Td>Bidang Jasa</Td>
              <Td>
                {detail.bidangjasa.map((item, i) => (
                  <Tag key={i} variant="solid" colorScheme="teal">
                    {item.kasi.name}
                  </Tag>
                ))}
              </Td>
            </Tr>
            <Tr>
              <Td>Identity Photo</Td>
              <Td>
                <AspectRatio maxW="500px" ratio={8 / 5}>
                  <Zoom>
                    <Image src={`${envs.imageUrl}/${detail.partnerregistration[0].identityPhoto}`} alt="Identity Photo" objectFit="cover" />
                  </Zoom>
                </AspectRatio>
              </Td>
            </Tr>
            <Tr>
              <Td>Photo </Td>
              <Td>
                <AspectRatio ratio={2 / 3}>
                  <Zoom>
                    <Image src={`${envs.imageUrl}/${detail.partnerregistration[0].swaPhoto}`} alt="Swa Photo" objectFit="cover" />
                  </Zoom>
                </AspectRatio>
              </Td>
            </Tr>
            <Tr>
              <Td>Npwp </Td>
              <Td>
                {detail.partnerregistration[0].npwpPhoto ? (
                  <AspectRatio maxW="500px" ratio={8 / 5}>
                    <Zoom>
                      <Image src={`${envs.imageUrl}/${detail.partnerregistration[0].npwpPhoto}`} alt="NPWP Photo" objectFit="cover" />
                    </Zoom>
                  </AspectRatio>
                ) : (
                  "Kosong"
                )}
              </Td>
            </Tr>
            <Tr>
              <Td>Verifikasi Email</Td>
              <Td>{detail.emailVerified ? <ImCheckmark /> : <ImCross />}</Td>
            </Tr>
          </Tbody>
        </Table>
      ) : (
        detail && (
          <Box>
            <Table variant="striped" colorScheme="blue" size="sm">
              <Tbody>
                <Tr>
                  {console.log("ini det", detail)}
                  <Td>Name</Td>
                  <Td>{detail.name}</Td>
                </Tr>
                <Tr>
                  <Td>User ID</Td>
                  <Td>{detail.id}</Td>
                </Tr>
                <Tr>
                  <Td>Created At</Td>
                  <Td>{moment(detail.createdAt).utc().format("LLLL")}</Td>
                </Tr>
                <Tr>
                  <Td>Email</Td>
                  <Td>{detail.email}</Td>
                </Tr>
                <Tr>
                  <Td>No Telp</Td>
                  <Td>{detail.phoneNumber}</Td>
                </Tr>
                <Tr>
                  <Td>Photo Profile</Td>
                  <Td>{detail.photo ? <Image src={detail.photo} alt="Swa Photo" objectFit="cover" /> : "tidak ada foto profile"}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        )
      )}
    </Fragment>
  );
};

export default ModalUser;
