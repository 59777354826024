import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue, Stack, Heading, HStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  StarIcon,
  Center,
  Formik,
  Form,
  Field,
  Button,
  Flex,
  Text,
  Link,
  Checkbox,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Select,
} from "@chakra-ui/react";
import * as Yup from "yup";
import axios from "axios";
import envs from "../../envs";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import { useToast } from "@chakra-ui/toast";

function CreatSearch() {
  const history = useHistory();
  const [kasi, setKasi] = useState([]);

  let initialValues = {
    query: "",
  };
  //state for title and content
  const [collection, setCollection] = useState(initialValues);

  const [load, setLoad] = useState(false);

  const handleSubmit = async () => {
    setLoad(true);
    console.log(collection);
    await apiPos
      .post(`/v1/search`, collection, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setLoad(false);
        toast({
          title: "Submit Berhasil",
          description: "submit search suggestion berhasil",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        // console.log(res);
      })
      .catch((e) => {
        setLoad(false);
        // console.log("ini e", e.message);
        toast({
          title: "Submit Gagal",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    setCollection(initialValues);

    history.replace("/searchlist");
  };

  const inputOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  const { toggleColorMode } = useColorMode();
  const toast = useToast();
  return (
    <Fragment>
      {console.log("ini collec", collection)}
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Create Suggestion</Center>
            </Heading>
          </Stack>
          <br />
          <Box as={"form"}>
            <Stack spacing={4}>
              <FormControl id="name">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Suggestion Search</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="...." name="query" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>

              <br />
              <Stack spacing={10}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  {load ? (
                    <Stack direction={"row"} spacing={4} align={"center"}>
                      <Spinner />
                      <Text>Loading...</Text>
                    </Stack>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreatSearch;
