import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Center,
} from "@chakra-ui/react";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import { Redirect } from "react-router-dom";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Stack, Checkbox } from "@chakra-ui/react";
import { boolean } from "yup";
import { Image } from "@chakra-ui/react";
import Icon from "../../img/Icon.png";
import moment from "moment";

const ModalUser = ({ handleChange, detailUser }) => {
  const [user, setUser] = useState(null);

  // handle change form
  const handleOnChange = (e) => {
    // kirim state ke parent
    handleChange(e);
  };

  return (
    <Fragment>
      {detailUser && (
        <Table variant="striped" colorScheme="blue" size="sm">
          {console.log(detailUser)}
          <Tbody>
            <Tr>
              <Td>Name</Td>
              <Td>{detailUser.name}</Td>
            </Tr>
            <Tr>
              <Td>Email</Td>
              <Td>{detailUser.email}</Td>
            </Tr>
            <Tr>
              <Td>City</Td>
              <Td>{detailUser.city}</Td>
            </Tr>
            <Tr>
              <Td>Phone Number</Td>
              <Td>{detailUser.phoneNumber}</Td>
            </Tr>
            <Tr>
              <Td>Mitra level</Td>
              <Td>{detailUser.level.roleName}</Td>
            </Tr>
            <Tr>
              <Td>Last Online</Td>
              <Td>{moment(detailUser.lastOnline).utc().format("LLLL")}</Td>
            </Tr>
            <Tr>
              <Td>Photo</Td>
              <Td>
                {detailUser.photo ? (
                  <AspectRatio>
                    <Image src={`${envs.imageUrl}/${detailUser.photo}`} alt="Identity Photo" objectFit="cover" fallbackSrc={Icon} />
                  </AspectRatio>
                ) : (
                  <AspectRatio maxW="100px" maxH="200px" ratio={1 / 2}>
                    <Image src={Icon} alt="Identity Photo" objectFit="cover" />
                  </AspectRatio>
                )}
              </Td>
            </Tr>
          </Tbody>
          {console.log("photo", `${envs.imageUrl}/${detailUser.photo}`)}
        </Table>
      )}
    </Fragment>
  );
};

export default ModalUser;
