const apiProd = "https://kita.kasisolusi.com/api";
const Prod = "https://is3.cloudhost.id/kasisolusi-s3-bucket";
const apiStaging = "https://ksdev.kasisolusi.com/api"; //apidev.kasisolusi.id
//http://159.223.42.21:5001/api
const Staging = "https://is3.cloudhost.id/kasisolusi-s3-bucket"; //di apidev tidak bisa tampil gambar
const apiLocal = "http://192.168.88.20:3030/logistics/api";
const Local = "http://192.168.88.20:3030/logistics/";

// S3
const URL = "is3.cloudhost.id";
const KEY = "8N0NANWKI8KY6RX7BXWN";
const SECRET = "isOl8f2quhTqq4aBaOJPxnAjxwSL3tXANSp94Nlu";
const BUCKET = "kasisolusi-s3-bucket";

const envs = {
  apiUrl: apiProd,
  appNumber: "20210621",
  imageUrl: Prod,
  S3_URL: URL,
  S3_KEY: KEY,
  S3_SECRET: SECRET,
  S3_BUCKET: BUCKET,
};

export default envs;
