import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue, Stack, Heading, HStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  StarIcon,
  Center,
  Formik,
  Form,
  Field,
  Button,
  Flex,
  Text,
  Link,
  Checkbox,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Select,
} from "@chakra-ui/react";

import * as Yup from "yup";
import axios from "axios";
import envs from "../../envs";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import "./Create.css";
import { useDispatch, useSelector } from "react-redux";

function CreatClass() {
  const [loginFail, setLoginFail] = useState(null);
  const history = useHistory();
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);
  const [load, setLoad] = useState(false);

  const { userId, loading, error } = useSelector((state) => state.userIDReducer);

  const dispatch = useDispatch();

  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState(null);

  let initialValues = {
    title: "",
    description: "",
    classCategoryId: "",
  };
  //state for title and description
  const [collection, setCollection] = useState(initialValues);

  const handleSubmit = async () => {
    setLoad(true);
    var bodyFormData = new FormData();

    bodyFormData.append("json", JSON.stringify(collection));
    bodyFormData.append("image", selectedFile);

    // Display the key/value pairs
    for (var pair of bodyFormData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    let result2 = await apiPos
      .post(`/v1/class/course`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: "set",
          userId: res.data.data.uploadUrl,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    let result = await apiPos
      .post(`/v1/class/create`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoad(false);
        history.replace(`/addsubclass/?classId=${res.data.data.classes.id}`);
      })
      .catch((e) => {
        setLoad(false);
        console.log(e);
      });
  };

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  //   // On file upload (click the upload button)
  //   const onFileUpload = async (collection,image) => {
  //     // Create an object of formData
  //     var formData = new FormData();

  //     // Update the formData object
  //     formData.append(
  //       'json', JSON.stringify(collection)
  //     );
  //     formData.append(
  //       'image', image
  //     );

  //     // Details of the uploaded file
  //     console.log("selected file",selectedFile);
  //     console.log("form data",formData);
  //     // uploadVideo()
  //     // // Request made to the backend api
  //     // // Send formData object
  //     // const api="https://storage.googleapis.com/video-storage-us-east1-uploads/AMWmAZaHuVTHvBgJcc8aobsCPJ3vCjKhP4gWbocDxs00?Expires=1631080889&GoogleAccessId=direct-uploads-writer-prod%40mux-cloud.iam.gserviceaccount.com&Signature=Jtm31oqzd0%2FF2D5VKxgqrIzzpxiGhhRvVdDPaAQg38CMhYwZ7HdFsyy0cwMm7QV5RfqQuxAn19GPxJ2CbT44MpsVW4M6guyh9e6X4XjQI5VOq9tLzi%2BYJrSJ0wpfRDrbgLZuK46oblB2lst%2FJDK96ax5O7uLJDFURPaagWX62Zixi9osJnGlSqNFMPnLwrqZZVXzMIu4c5cwxM33aCNc%2FWD70uJhBRIjBBi01CS8iv5%2FDZkng%2Fk1V79N61ADg52UwFZacQrtU27TuX1UmJODoZEybvaj1DHZWO0R6cUXyj1JBY5RIxFJKkXh7%2BzfBYBxApxJl3jmvBZAXrgLp4%2B2Iw%3D%3D&upload_id=ADPycdvhTikErUqjkU9vZe5wL3wzv3C5rhdvhfhU_gBkTRF8G5vUYhV26_WKi4N3g2HmaZ5tnCHLj9z2-yRRhNj6Y3YrD7Lv6w"
  //     // axios.put(api, formData)
  //     // .then((res) => {
  //     //     console.log("berhasil",res)
  //     // })
  //     // .catch((err) => {
  //     //   console.log("gagal",err);

  //     // });

  //   };

  const getAPI = () => {
    apiPos
      .get(`/v1/class/classcategories`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setCategories(res.data.data.categories);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const inputOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //   //set description value every time text changes
  //   setCollection({
  //     ...collection,
  //     "description": text
  //   });
  //   console.log(collection.description)
  // }, [text]);

  useEffect(() => {
    getAPI();
  }, []);

  const { toggleColorMode } = useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Create Class</Center>
            </Heading>
          </Stack>
          <br />
          <Box as={"form"}>
            <Stack spacing={4}>
              {loginFail ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle mr={2}>Login Failed</AlertTitle>
                  <AlertDescription>{loginFail.message}</AlertDescription>
                  {/* <CloseButton position="absolute" right="5px" top="8px" /> */}
                </Alert>
              ) : null}
              <FormControl id="title">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Title</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="Example: Learn Photoshop CS6 from Scratch" name="title" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="description">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Description</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="deskripsi...." name="description" onChange={inputOnChange} />
                    {/* <Input type="text"
                          placeholder="Description"
                          name="description"
                          onChange={inputOnChange} /> */}
                    {/* <CKEditor
                    editor={ ClassicEditor }
                    data={text}
                    // onReady={ editor => {
                    //     // You can store the "editor" and use when it is needed.
                    //     console.log( 'Editor is ready to use!', editor );
                    // } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setText(data)
                    } }
                    // onBlur={ ( event, editor ) => {
                    //     console.log( 'Blur.', editor );
                    // } }
                    // onFocus={ ( event, editor ) => {
                    //     console.log( 'Focus.', editor );
                    // } }
                /> */}
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="classCategoryId">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Category</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Select placeholder="Choose a category" name="classCategoryId" onChange={inputOnChange}>
                      {categories.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="image">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Upload image</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input variant="filled" type="file" size="sm" onChange={onFileChange} />
                  </Box>
                </Stack>
              </FormControl>
              <br />
              <Stack spacing={10}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  {load ? (
                    <Stack direction={"row"} spacing={4} align={"center"}>
                      <Spinner />
                      <Text>Loading...</Text>
                    </Stack>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreatClass;
