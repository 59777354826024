import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Box, Image, FormControl, FormLabel, Input, Checkbox, Stack, Link, Button, Heading, Text, useColorModeValue, useColorMode, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton, Spinner } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import envs from "../../../envs";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../img/LogoKasisolusi.png";

export default function SimpleCard() {
  const [loginFail, setLoginFail] = useState(null);
  const history = useHistory();

  let [load, setLoad] = useState(false);

  const { user, loading, error } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  let initialValues = {
    email: "",
    password: "",
  };

  let validationSchema = Yup.object({
    email: Yup.string().email("Email tidak valid").required("Email harus diisi").lowercase(),
    password: Yup.string().required("Password harus diisi"),
  });

  const formOnSubmit = async (val) => {
    setLoad(true);
    setLoginFail(null);

    await axios
      .post(`${envs.apiUrl}/v1/auth/login`, val, {
        headers: {
          originsource: "admin",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          Cookies.set("token", res.data.data.user.token, {
            expires: new Date(res.data.data.user.tokenExpires),
          });
          Cookies.set("user", res.data.data.user.name, {
            expires: new Date(res.data.data.user.token.tokenExpires),
          });
          Cookies.set("userID", res.data.data.user.id, {
            expires: new Date(res.data.data.user.token.tokenExpires),
          });
          Cookies.set("role", res.data.data.user.role, {
            expires: new Date(res.data.data.user.token.tokenExpires),
          });
          dispatch({
            type: "set",
            user: res.data.data.user,
            loading: false,
          });
          history.replace("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoginFail(err.response.data);
      });
    setLoad(false);
  };

  let formik = useFormik({
    initialValues,
    onSubmit: formOnSubmit,
    validationSchema,
  });

  const inputOnChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  console.log("Formik Values", formik.values);
  console.log("Formik errors", formik.errors);
  const { toggleColorMode } = useColorMode();
  const formBackground = useColorModeValue("gray.100", "grey.700");
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={formBackground}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          {/* <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text> */}
          <Box mb={4}>
            <Image src={Logo} alt="Logo Kasisolusi" />
          </Box>
        </Stack>
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Box as={"form"} mt={10}>
            <Stack spacing={4}>
              {loginFail ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle mr={2}>Login Failed</AlertTitle>
                  <AlertDescription>{loginFail.message}</AlertDescription>
                  {/* <CloseButton position="absolute" right="5px" top="8px" /> */}
                </Alert>
              ) : null}
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input type="text" placeholder="email" autoComplete="email" name="email" onChange={inputOnChange} />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input type="password" placeholder="Password" autoComplete="password" name="password" onChange={inputOnChange} />
              </FormControl>
              <Stack spacing={10}>
                <Stack direction={{ base: "column", sm: "row" }} align={"start"} justify={"space-between"}>
                  <Checkbox>Remember me</Checkbox>
                  {/* <Link color={"blue.400"}>Forgot password?</Link> */}
                </Stack>
                <Button
                  onClick={formik.handleSubmit}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  {load ? (
                    <Stack direction={"row"} spacing={4} align={"center"}>
                      <Spinner />
                      <Text>Loading...</Text>
                    </Stack>
                  ) : (
                    "Login"
                  )}
                </Button>
                {/* <Button onClick={toggleColorMode}>Change background</Button> */}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
}
