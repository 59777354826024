import Cookies from "js-cookie";
import envs from "../envs";
const ckEditorRemoveTags =(data) =>{ 
    return data .replace('<p><img', '<img')
                .replace('<h1><img', '<img')     //ini blm di deploy
                .replace('<p style="text-align:right;"><img', '<img')
                .replace('<p style="text-align:center;"><img', '<img')
                .replace('<p style="text-align:justify;"><img', '<img')  
    
  }
  const editorConfiguration = {

    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'alignment',
        '|',
        'outdent',
        'indent',
        'fontColor',
        'fontFamily',
        'fontBackgroundColor',
        'fontSize',
        // 'horizontalLine',
        // 'highlight',
        '|',
        // 'imageUpload',
        'imageInsert',
        'blockQuote',
        // 'insertTable',
        // 'mediaEmbed',
        'undo',
        'redo',
        '|',
        // 'specialCharacters',
        'sourceEditing'
      ]
    },
    language: 'en',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage'
      ],
      insert: {
        type : 'inline'
      }
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    },
      licenseKey: '',
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: 'http://kasisolusi.id/s/api/v1/uploadgeneral',

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: true,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
         
        }
    }
};

export {ckEditorRemoveTags,editorConfiguration};