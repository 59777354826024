import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {  useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select
  } from "@chakra-ui/react"
  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";
  import { apiPos } from "../../utils/axios";
  import Cookies from "js-cookie";


function CreatFormField() {

  const history = useHistory();
  const [text,setText]=useState("");
  const [categories,setCategories]=useState([]);


  let initialValues = {
    title: "",
    name: "",
    type: "",
    formType: "",
    value: "",
    default: "",
    options: "",
    validation: "",
  };
    //state for title and content
  const [collection,setCollection]=useState(initialValues)

  let [load, setLoad] = useState(false);


  const handleSubmit = async () => {
    setLoad(true)

    await apiPos.post(`/v1/formfield/`,collection ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
       
      },
    })
    .then((res) => {
      setLoad(false);
      setTimeout(function(){ history.replace("/formfield") }, 2000);
     
      console.log(res)
    })
    .catch((e) => {
      setLoad(false);
      console.log(e);
    }); 
    
      // console.log(collection)
  };





  const inputOnChange = (e) => {

    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };







  const{toggleColorMode}= useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Create Form Field</Center>
            </Heading>
            </Stack>
            <br />
            <Box as={'form'}  >
              <Stack spacing={4} >
                <FormControl id="title" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Title</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="title" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="name" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Name</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="name" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="type" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>type</FormLabel>
                </Box>
                <Box w="75%">
                  {/* <Input type="text"  placeholder="...." name="type" onChange={inputOnChange}/> */}
                  <Select placeholder="Select option" name="type" onChange={inputOnChange}>
                    <option value="datepicker">Datepicker</option>
                    <option value="location">Location</option>
                  </Select>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="formType" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Form Type</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="formType" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="value" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Value</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="value" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="default" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Default</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="default" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="options" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Options</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="options" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="validation" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Validation</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="validation" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
              </Stack>
            </Box>            
          </Box>
      </Box>
    </Fragment>
  );
}

export default CreatFormField;