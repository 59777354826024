import axios from 'axios';
import envs from "../../envs";
import React,{Fragment,useState,useEffect} from 'react'; 
import { useLocation } from 'react-router-dom'; 
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    useColorModeValue,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    CloseButton,
    useDisclosure,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Heading,
    Stack,
    Spinner,
    Text,
    Center,
    Input,
    Fade, 
    ScaleFade, 
    Slide, 
    SlideFade,
    Divider,
    Collapse,
    FormControl,
    FormLabel
  } from "@chakra-ui/react"
  // import { CKEditor } from '@ckeditor/ckeditor5-react';
  // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import { useFormik } from "formik";
  import Mux from '@mux/mux-node';
  import * as UpChunk from '@mux/upchunk';
  import "./Create.css";
  import { useDispatch, useSelector } from "react-redux";

  function FormSubClass({counts}) { 
    const {search} = useLocation();
    const searchParams= new URLSearchParams(search);
    const [load, setLoad] = useState(false);

    const { userId, loading, error } = useSelector(
      (state) => state.userIDReducer
    );

    const [text,setText]=useState('');

    // Initially, no file is selected 
    const[selectedFile,setSelectedFile]=useState(null)
  
    let initialValues = {
      classId:"",
      title: "",
      description: "",
    };
      //state for title and description
    const [collection,setCollection]=useState(initialValues)


    // state = { 
    //   selectedFile: null
    // }; 


     
    // On file select (from the pop up) 
    const onFileChange = event => { 
      // Update the state 
      //this.setState({ selectedFile: event.target.files[0] }); 
      setSelectedFile(event.target.files[0]);      
    }; 
     

    // const [uploadUrl,setUploadUrl]=useState(null)

    // // On file upload (click the upload button) 
    // const onFileUpload = () => { 
    //   //initialize uploadUrl as null
    //   setUploadUrl(null);

    //   //save title and description , then get upload url from API
    //   const response = axios.post(`${envs.apiUrl}/v1/class/course`, collection).then((res) => {
    //     console.log("respon".res)
     
    //    })
    //    .catch((e) => {
    //      console.log(e);
    //    });
    //   // if(response.data.status == 'success'){
    //   //   uploadUrl= response.data.data.uploadUrl;
    //   // }
    //   console.log("url",uploadUrl);
  
    // }; 

    


    const uploadVideo = async () =>{
      setLoad(true)
      let uploadUrl=userId;

      //if uploadUrl not null upload to mux
      if(uploadUrl){
        const upload = UpChunk.createUpload({
          endpoint: uploadUrl,
          file: selectedFile,
          chunkSize: 5120, // Uploads the file in ~5mb chunks
        });
        
        // subscribe to events
        upload.on('error', err => {
          console.error('💥 🙀', err.detail);
          setLoad(false)
        });
        
        upload.on('progress', progress => {
          console.log('Uploaded', progress.detail, 'percent of this file.');
          
        });
        
        // subscribe to events
        upload.on('success', err => {
          console.log("Wrap it up, we're done here. 👋");
          setLoad(false)
        });  
      }
    };  

    // File content to be displayed after 
    // file upload is complete 
    const fileData = () => { 
      if (selectedFile) { 
          
        return ( 
          <Stack direction={["row"]} spacing="24px" py="5">
            <Box w="25%">
            </Box>
          <Box w="75%">
            <Text>File Details:</Text> 
            <Text>File Name: {selectedFile.name}</Text> 
            <Text>File Type: {selectedFile.type}</Text>      
          </Box> 
          </Stack>
        ); 
      } else { 
        return ( 
        <Stack direction={["row"]} spacing="24px" py="5">
                    <Box w="25%">
             
          </Box>
          <Box w="75%">
             Choose before Pressing the Submit button
          </Box>
        </Stack>
        ); 
      } 
    }; 

    const formOnSubmit = async (val) => {

      alert(JSON.stringify(val))
      
    };
    


    let formik = useFormik({
      initialValues,
      onSubmit: formOnSubmit
  
    });
    const inputOnChange = (e) => {
      setCollection({
        ...collection,
        [e.target.name]: e.target.value,
      });
    };
  
    useEffect(() => {
      //set description value every time text changes
      formik.setFieldValue("description", text);
    }, [text]);


    useEffect(() => {
    setCollection({
      ...collection,
      classId:searchParams.get('classId'),
    });
    
  },[]);


    return ( 
      <Fragment> 
        
      <Box       
        p={5}
        shadow="md"
        borderWidth="1px"
        flex="1"
        borderRadius="md"
        bg={useColorModeValue('white', 'gray.700')}> 
          <Heading fontSize="xl" mb={4}>Sub Class {counts}<Divider /></Heading> 
          <Stack spacing={4}>
            <Stack isInline>
              <Box w="100%"  as={'form'}>
              <FormControl id="title" isRequired>
                <Stack direction={["row"]} spacing="24px" py="5">
                  <Box w="25%">
                    <FormLabel>Title</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text"  placeholder="Example: Learn Photoshop CS6 from Scratch" name="title" onChange={inputOnChange}/>
                  </Box>
                  </Stack>
                  <Stack direction={["row"]} spacing="24px" py="5">
                  <Box w="25%">
                    <FormLabel>File Video</FormLabel>
                  </Box>
                  <Box w="75%">
                  <Input variant="filled" type="file"  size="sm" onChange={onFileChange} /> 
                  </Box>
                </Stack>
              </FormControl>
              {fileData()} 
              </Box>
              </Stack>

            <Stack direction={["row"]} spacing="24px" py="5">
              <Box w="25%">
                <FormLabel>Description</FormLabel>
              </Box>
              <Box w="75%">
                 <Input type="text"  placeholder="deskription...." name="description" onChange={inputOnChange}/>
                {/* <CKEditor
                    editor={ ClassicEditor }
                    data={text}
                      // onReady={ editor => {
                      //     // You can store the "editor" and use when it is needed.
                      //     console.log( 'Editor is ready to use!', editor );
                      // } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setText(data)
                    } }
                      // onBlur={ ( event, editor ) => {
                      //     console.log( 'Blur.', editor );
                      // } }
                      // onFocus={ ( event, editor ) => {
                      //     console.log( 'Focus.', editor );
                      // } }
                /> */}
                </Box> 
              </Stack>  
              
          {/* <Button onClick={onFileUpload} colorScheme="blue" variant="solid">Upload!</Button> */}
          <Stack spacing={10}>
                  <Button
                    onClick={()=>
                      uploadVideo()}
                    colorScheme="blue" 
                    variant="solid"
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
          </Stack> 
      </Box> 
  </Fragment>
      ); 
    
  } 
  
  export default FormSubClass; 

