import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Cookies from "js-cookie";
import PrivateRoute from "./router/privaterouter";
import PrivateRouteAdmin from "./router/privaterouterAdmin";
import Login from "./view/pages/login/login";
import Live from "./view/pages/live/live";
import Product from "./view/pages/product/product";
import Dashboard from "./view/dashboard/Dashboard";
import User from "./view/user/user-list/UserList";
import UserListNotApproved from "./view/user/user-list/UserListNotApproved";
import CountMitra from "./view/countmitra/CountMitra";
import SubmitTask from "./view/task/SubmitTask";
import UploadTask from "./view/task/UploadTask";
import CreateClass from "./view/create/CreateClass";
import AddSubClass from "./view/create/AddSubClass";
import NewsList from "./view/news/NewsList";
import CreateNews from "./view/news/CreateNews";
import SliderList from "./view/slider/SliderList";
import CreateSlider from "./view/slider/CreateSlider";
import YoutubeList from "./view/youtube/YoutubeList";
import CreateYoutube from "./view/youtube/CreateYoutube";
import VoucherList from "./view/voucher/VoucherList";
import CreateVoucher from "./view/voucher/CreateVoucher";
import PageMap from "./view/PageMap/PageMapList";
import CreatePageMap from "./view/PageMap/CreatePageMap";
import KasiList from "./view/kasi/KasiList";
import CreateKasi from "./view/kasi/CreateKasi";
import FormField from "./view/FormField/FormFieldList";
import CreateFormField from "./view/FormField/CreateFormField";
import TermCondition from "./view/term/TermConditionList";
import CreateTermCondition from "./view/term/CreateTermCondition";
import Project from "./view/project/ProjectList";
import Tag from "./view/tag/TagList";
import CreateTag from "./view/tag/CreateTag";
import TicketList from "./view/ticket/TiketList";
import ViewTicket from "./view/ticket/ViewTicket";
import Submission from "./view/pages/submission/Submission";
import TalentRequest from "./view/talent/TalentRequest";
import Portfolio from "./view/portfolio/Portfolio";
import PortfolioDetail from "./view/portfolio/PortfolioDetail";
import PortfolioCreate from "./view/portfolio/CreatePortfolio";
import CreateSearch from "./view/search/CreateSearch";
import SearchList from "./view/search/SearchList";
export const UserContext = React.createContext();

const App = () => {
  return (
    <BrowserRouter basename="/kasisolusi">
      <Switch>
        {/* static html view here */}
        <PrivateRoute path="/" exact component={Dashboard} />
        <PrivateRoute path="/dashboard" component={Dashboard} />

        <PrivateRoute path="/portfolio" component={Portfolio} />
        <PrivateRoute path="/portfoliodetail" component={PortfolioDetail} />
        <PrivateRoute path="/createportfolio" component={PortfolioCreate} />

        <PrivateRouteAdmin path="/ticketlist" component={TicketList} />
        <PrivateRouteAdmin path="/users" component={User} />
        <PrivateRouteAdmin path="/usersnotapprove" component={UserListNotApproved} />
        <PrivateRouteAdmin path="/countmitra" component={CountMitra} />
        <PrivateRouteAdmin path="/submittask" component={SubmitTask} />
        <PrivateRouteAdmin path="/uploadtask" component={UploadTask} />
        <PrivateRouteAdmin path="/createclass" component={CreateClass} />
        <PrivateRouteAdmin path="/addsubclass" component={AddSubClass} />
        <PrivateRouteAdmin path="/newslist" component={NewsList} />
        <PrivateRouteAdmin path="/createnews" component={CreateNews} />
        <PrivateRouteAdmin path="/sliderlist" component={SliderList} />
        <PrivateRouteAdmin path="/createslider" component={CreateSlider} />
        <PrivateRouteAdmin path="/youtubelist" component={YoutubeList} />
        <PrivateRouteAdmin path="/createyoutube" component={CreateYoutube} />
        <PrivateRouteAdmin path="/voucherlist" component={VoucherList} />
        <PrivateRouteAdmin path="/createvoucher" component={CreateVoucher} />
        <PrivateRouteAdmin path="/pagemap" component={PageMap} />
        <PrivateRouteAdmin path="/createpagemap" component={CreatePageMap} />
        <PrivateRouteAdmin path="/kasilist" component={KasiList} />
        <PrivateRouteAdmin path="/createkasi" component={CreateKasi} />
        <PrivateRouteAdmin path="/formfield" component={FormField} />
        <PrivateRouteAdmin path="/createformfield" component={CreateFormField} />
        <PrivateRouteAdmin path="/termcondition" component={TermCondition} />
        <PrivateRouteAdmin path="/createtermcondition" component={CreateTermCondition} />
        <PrivateRouteAdmin path="/project" component={Project} />
        <PrivateRouteAdmin path="/tag" component={Tag} />
        <PrivateRouteAdmin path="/createtag" component={CreateTag} />
        <PrivateRouteAdmin path="/viewticket" component={ViewTicket} />
        <PrivateRouteAdmin path="/talentrequest" component={TalentRequest} />
        <PrivateRouteAdmin path="/createsearch" component={CreateSearch} />
        <PrivateRouteAdmin path="/searchlist" component={SearchList} />

        {/* routing */}
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/live">
          <Live />
        </Route>
        <Route path="/product">
          <Product />
        </Route>
        <Route path="/submission">
          <Submission />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
