import React, { Fragment, useState, useEffect, useRef } from "react";
import { Box, useColorModeValue, Stack, Heading, HStack } from "@chakra-ui/react";
import { Redirect, useHistory } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  StarIcon,
  Center,
  Formik,
  Form,
  Field,
  Button,
  Flex,
  Text,
  Link,
  Checkbox,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Select,
  Image,
  Spacer,
  VStack,
  Textarea,
  Tag,
  Divider,
  StackDivider,
} from "@chakra-ui/react";
import { ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { saveAs } from "file-saver";
import moneyFormat, { formatBytes } from "../../../helper/helper";
import { io } from "socket.io-client";
import envs from "../../../envs";
import { apiPos } from "../../../utils/axios";
import LogoBulatBgBlue from "../../../img/LogoBulatBgBlue.png";
import Logo from "../../../img/LogoKasisolusi.png";
import moment from "moment";
import QRCodeStyling, { DrawType, TypeNumber, Mode, ErrorCorrectionLevel, DotType, CornerSquareType, CornerDotType, Extension, Options } from "@nimashoghi/qr-code-styling";
import AWS from "aws-sdk";

// Aws Setting start
const spacesEndpoint = new AWS.Endpoint(envs.S3_URL);
const S3 = new AWS.S3({
  endpoint: spacesEndpoint,
  credentials: {
    accessKeyId: envs.S3_KEY,
    secretAccessKey: envs.S3_SECRET,
  },
});
AWS.config.httpOptions.timeout = 0;
// Aws Setting end

//qr generator setting start
const qrCode = new QRCodeStyling({
  width: 400,
  height: 400,
  data: "https://qr-code-styling.com",
  margin: 0,
  qrOptions: {
    typeNumber: "0",
    mode: "Byte",
    errorCorrectionLevel: "Q",
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 0,
  },
  dotsOptions: {
    type: "classy-rounded",
    color: "black",
  },
  image: LogoBulatBgBlue,
});
//qr generator setting end

function Submission() {
  const [InputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
  const history = useHistory();
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);
  const [load, setLoad] = useState(false);
  const [showToken, setShowToken] = useState(true);
  const [showLampiran, setShowLampiran] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showprogress, setShowprogress] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [qr, setQr] = useState("");
  const [project, setProject] = useState(null);
  const [form, setForm] = useState(false);

  const [token, setToken] = useState(null);

  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState(null);

  let initialValuesNew = {
    projecttaskId: 1,
    fileName: "",
    fileSize: 0,
    pathName: "",
    notes: "",
    isSubmission: 0,
    isSendChat: false,
  };
  //state for title and content
  const [collection, setCollection] = useState(initialValuesNew);

  let initialValues = {
    projecttaskId: 1,
    status: "review",
    revision: null,
    updateType: "toReview",
  };
  //state for title and content
  const [status, setStatus] = useState(initialValues);

  // qr generator start
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: qr,
    });
  }, [qr]);
  // qr generator end

  // On file select (from the pop up)
  const onFileChange = (event) => {
    //update filename state
    setCollection((prevState) => ({
      ...prevState,

      fileName: event.target.files[0].name,
    }));
    // Update the state
    setSelectedFile(event.target.files[0]);
    handleSubmitS3(event.target.files[0]);
  };

  const handleSubmitS3 = async (data) => {
    try {
      setLoad(true);
      setShowprogress(true);
      const originalName = data.name;
      // if (selectedFile.name) {
      //   const originalName = selectedFile.name;
      // }
      const devPrefix = "DEVELOPMENT-";
      const pathName = `uploads/attachments/${devPrefix}${Date.now()}-${originalName}`;
      const putObj = await S3.putObject({
        Bucket: envs.S3_BUCKET,
        Key: pathName,
        Body: data,
        ACL: "public-read",
        ContentType: data.type,
        ContentDisposition: "inline;",
      })
        .on("httpUploadProgress", function (evt) {
          setProgress(parseInt((evt.loaded * 100) / evt.total));
        })
        .promise();

      setCollection((prevState) => ({
        ...prevState,
        pathName: pathName,
        fileSize: data.size,
      }));
      setLoad(false);
      setShowprogress(false);
    } catch (error) {
      setLoad(false);
      alert(error.message);
    }
  };

  const InputOnChangeForm2 = (e) => {
    setCollection((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (token.role == "mitra") {
      setCollection((prevState) => ({
        ...prevState,
        isSubmission: 1,
      }));
    }
  };

  const handleSubmitForm2 = async () => {
    if ((selectedFile == null && collection.notes == null) || collection.notes == "") {
      alert("Lampiran atau Catatan belum diisi");
    } else {
      setLoad(true);

      var bodyFormData = new FormData();
      bodyFormData.append("json", JSON.stringify(status));

      console.log("body", bodyFormData);

      // Display the key/value pairs
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      await apiPos
        .post(`/v1/projecttask/addattachment`, collection, {
          headers: {
            Authorization: `Bearer ${token.bearer}`,
            AppNumber: envs.appNumber,
          },
        })
        .then((res) => {
          setalertMassage(true);
        })
        .catch((e) => {
          setalertMassageFailed(true);
          console.log(e);
        });

      if (token.role == "mitra") {
        await apiPos
          .post(`/v1/projecttask/update`, bodyFormData, {
            headers: {
              Authorization: `Bearer ${token.bearer}`,
              AppNumber: envs.appNumber,
            },
          })
          .then((res) => {
            setalertMassage(true);
          })
          .catch((e) => {
            setalertMassageFailed(true);
            console.log(e);
          });
      }
      setLoad(false);
      setCollection(initialValuesNew);
      setSelectedFile(null);
      setToken(!token);
      setShowToken(!showToken);
      setTimeout(function () {
        setalertMassage(false);
        setalertMassageFailed(false);
      }, 4000);
      history.replace("/submission");
    }
  };

  //connect to socket Start
  const SOCKET_URL = "https://kita.kasisolusi.com/socketserver";
  const SOCKET_PATH = "/socketserver/socketio";
  let socket;

  useEffect(() => {
    socket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      query: {
        socketKey: "webAppSubmissionConsumer",
      },
      transports: ["websocket"],
    });
    socket.on("self", (data) => setQr(data));
    socket.on("project", (data) => {
      setToken(JSON.parse(data));
      showingToken(JSON.parse(data));
      // console.log("ini data", data);
      socket.disconnect();
    });
    socket.on("connect", () => {
      console.log("connect", socket.connected); // true
    });
    socket.on("disconnect", () => {
      console.log("disconnect", socket.disconnected); // true
    });
  }, []);

  //timer reconnect qr
  // const MINUTE_MS = 5000;

  // useEffect(() => {
  //   if (project === null) {
  //     const interval = setInterval(() => {
  //       socket.disconnect();
  //       socket.connect();
  //       console.log("Logs every 5 second");
  //     }, MINUTE_MS);

  //     return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  //   } else {
  //     return null;
  //   }
  // }, [project]);

  //connect to socket End

  const getAPI = (data) => {
    apiPos
      .get(`/v1/project/${data.projectId}`, {
        headers: {
          Authorization: `Bearer ${data.bearer}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setProject(res.data.data.projects);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const showingToken = (data) => {
    setShowToken(!showToken);
    getAPI(data);
    setCollection({
      ...collection,
      projecttaskId: data.projectId,
    });
    setStatus({
      ...status,
      projecttaskId: data.projectId,
    });
  };

  const toggleForm = () => {
    setForm(!form);
  };

  const downloadFile = (e) => {
    saveAs(`${envs.imageUrl}/${e.pathName}`, `${e.fileName}`); // Put your image url here.
  };

  const isJson = (param) => {
    try {
      return JSON.parse(param);
    } catch (e) {
      return null;
    }
  };

  const { toggleColorMode } = useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Center h="100px">
              <Image w="30%" src={Logo} alt="Logo Kasisolusi" />
            </Center>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>
                {/* Project  */}
                {token && `Project ${token.role.charAt(0).toUpperCase() + token.role.slice(1)}`}
              </Center>
            </Heading>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Submit Project Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Submit Project Failed
            </Alert>
          )}
          <br />
          {showToken && (
            <Stack spacing={4}>
              <Center>
                {/* <Image src={qr_image} alt="Qr Code" /> */}
                <Box>
                  <div ref={ref} />
                </Box>
              </Center>
            </Stack>
          )}
          {token && (
            <Fragment>
              {project && (
                <Fragment>
                  <Stack spacing={3}>
                    <Flex>
                      <Box>
                        <Tag variant="subtle" colorScheme="cyan" borderRadius="lg" size="lg" p={3}>
                          <Tag variant="solid" colorScheme="teal" mr={2}>
                            {project.projecttask[0].kasi.name}
                          </Tag>

                          <Heading as="h4" size="lg" isTruncated>
                            <Text fontWeight="bold">{project.name}</Text>
                          </Heading>
                        </Tag>
                      </Box>
                      <Spacer />
                      <Box p="3">
                        <HStack spacing="24px">
                          <Box>
                            <Box>
                              <Tag p={2} colorScheme="red">
                                <Text fontSize="2xl">{token.role}</Text>
                              </Tag>
                            </Box>
                          </Box>
                          <Box>
                            <Tag p={2} colorScheme="cyan">
                              <Text fontSize="2xl">
                                Status:{"  "} {project.status}
                              </Text>
                            </Tag>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>
                  </Stack>
                  <Box bg="#f7fafc">
                    <Box mt="8" p={4}>
                      <Stack spacing={4}>
                        <Text fontSize="2xl" fontWeight="bold">
                          {" "}
                          Brief
                        </Text>
                        <Divider />
                        <Text fontWeight="bold" p="4">
                          {project.projecttask[0].description}
                        </Text>
                        {project.projecttask[0].projecttaskaddon.length > 0 && (
                          <>
                            <Text fontWeight="bold">Layanan Tambahan</Text>
                            <Divider />
                            {project.projecttask[0].projecttaskaddon
                              .filter((item) => {
                                return item.isPaid == 1;
                              })
                              .map((addon, i) => {
                                return (
                                  <Fragment key={i}>
                                    <Flex>
                                      <Box p="4">
                                        <Text fontWeight="bold">{addon.name}</Text>
                                      </Box>
                                      <Spacer />
                                      <Box p="4">{moneyFormat(addon.price)}</Box>
                                    </Flex>
                                  </Fragment>
                                );
                              })}
                          </>
                        )}
                        {project.projecttask[0].projecttaskfield.length > 0 ? (
                          <>
                            <Text fontSize="2xl" fontWeight="bold">
                              {" "}
                              Info
                            </Text>
                            <Divider />
                          </>
                        ) : (
                          " "
                        )}

                        {project.projecttask[0].projecttaskfield.map((itemI, i) => {
                          let valueparse = isJson(itemI.value);
                          return (
                            <Fragment key={i}>
                              <Flex>
                                <Box p="4">
                                  <Text fontWeight="bold">{itemI.title}</Text>
                                </Box>
                                <Spacer />
                                {itemI.type == "location" ? (
                                  <Box>
                                    <Box p="4">{valueparse?.address}</Box>
                                  </Box>
                                ) : itemI.type == "datepicker" ? (
                                  <Box p="4">{moment(itemI.value).format("dddd, DD MMMM YYYY")}</Box>
                                ) : (
                                  <Box p="4">{itemI.value}</Box>
                                )}
                              </Flex>
                            </Fragment>
                          );
                        })}
                        <Text fontWeight="bold" fontSize="2xl">
                          {" "}
                          Attachments
                        </Text>
                        <Divider />

                        {project.projecttask[0].projectattachment
                          .filter((item) => {
                            return item.isSubmission == 0;
                          })
                          .map((item, i) => {
                            return (
                              <Fragment key={i}>
                                <Flex>
                                  <Box p="4">
                                    <Text fontWeight="bold">
                                      {item.fileName}
                                      {"       "}
                                      size: {"  "} {formatBytes(item.fileSize)}
                                    </Text>

                                    <Text>{item.notes}</Text>
                                  </Box>
                                  <Spacer />
                                  <Box p="4">
                                    <Button colorScheme="messenger" size="sm" variant="outline">
                                      <Link href={`${envs.imageUrl}/${item.pathName}`} isExternal>
                                        VIEW <ExternalLinkIcon mx="2px" />
                                      </Link>
                                    </Button>
                                    {"     "}
                                    <Button onClick={() => downloadFile(item)} colorScheme="teal">
                                      Download
                                    </Button>
                                  </Box>
                                </Flex>
                              </Fragment>
                            );
                          })}
                        <Text fontSize="2xl" fontWeight="bold">
                          {" "}
                          Submission
                        </Text>
                        <Divider />
                        {project.projecttask[0].projectattachment
                          .filter((item) => {
                            return item.isSubmission == 1;
                          })
                          .map((item, i) => {
                            return (
                              <Fragment key={i}>
                                <Flex>
                                  <Box p="4">
                                    <Text fontWeight="bold">
                                      {item.fileName}
                                      {"       "}
                                      size: {"  "} {formatBytes(item.fileSize)}
                                    </Text>

                                    <Text>{item.notes}</Text>
                                  </Box>
                                  <Spacer />
                                  <Box p="4">
                                    <Button colorScheme="messenger" size="sm" variant="outline">
                                      <Link href={`${envs.imageUrl}/${item.pathName}`} isExternal>
                                        VIEW <ExternalLinkIcon mx="2px" />
                                      </Link>
                                    </Button>
                                    {"     "}
                                    <Button onClick={() => downloadFile(item)} colorScheme="teal">
                                      Download
                                    </Button>
                                  </Box>
                                </Flex>
                              </Fragment>
                            );
                          })}
                      </Stack>
                    </Box>
                    {project.projecttask[0] && (
                      <Fragment>
                        {project.projecttask[0].revision && (
                          <Fragment>
                            <Box bg="#fcecbb" p={4} rounded="md">
                              <Center p={4}>
                                {/* 
                                {/* <Divider /> */}
                                {/*  */}
                                <VStack divider={<StackDivider borderColor="black" />} spacing={2}>
                                  <Text fontWeight="bold" color="#FFC107" fontSize="4xl">
                                    <InfoIcon />
                                    {"  "}
                                    Revisi
                                  </Text>
                                  <Text fontSize="xl">{project.projecttask[0].revision}</Text>
                                </VStack>
                              </Center>
                            </Box>
                          </Fragment>
                        )}
                      </Fragment>
                    )}

                    {token && token.role == "mitra"
                      ? project.projecttask[0].status == "review" && (
                          <Box bg="#a2bff5" p={4} rounded="md">
                            <Center p={4}>
                              <Text fontWeight="bold" color="#1251c7" fontSize="4xl">
                                <InfoIcon />
                                {"  "}
                                Submission in-review.
                              </Text>
                            </Center>
                          </Box>
                        )
                      : ""}
                    {!form && (
                      <Center m={6}>
                        {token.role == "mitra" && project.status == "paid" ? (
                          ""
                        ) : (
                          <Button onClick={toggleForm} size="md" colorScheme="blue" _hover={{ boxShadow: "md" }}>
                            &nbsp;&nbsp;{token && token.role == "mitra" ? "Upload Submission" : "Upload Attachment"}
                          </Button>
                        )}
                      </Center>
                    )}

                    {form && (
                      <Box as={"form"} mt="8" p={4}>
                        <Stack spacing={4}>
                          <FormControl id="icon">
                            <Stack direction={["row"]} spacing="24px">
                              <Box w="25%">
                                <FormLabel>Lampiran</FormLabel>
                              </Box>
                              <Box w="75%">
                                <Input variant="filled" type="file" size="sm" onChange={onFileChange} />
                              </Box>
                            </Stack>
                          </FormControl>
                          <FormControl id="catatan">
                            <Stack direction={["row"]} spacing="24px">
                              <Box w="25%">
                                <FormLabel>Catatan</FormLabel>
                              </Box>
                              <Box w="75%">
                                <Input type="text" placeholder="Input Catatan..." name="notes" onChange={InputOnChangeForm2} required />
                              </Box>
                            </Stack>
                          </FormControl>
                          <br />
                          <Stack spacing={10}>
                            {load ? (
                              <Button
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                  bg: "blue.500",
                                }}
                              >
                                <Stack direction={"row"} spacing={4} align={"center"}>
                                  <Spinner />
                                  {showprogress ? <Text>Uploaded {progress} %</Text> : <Text>Loading...</Text>}
                                </Stack>
                              </Button>
                            ) : (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubmitForm2();
                                }}
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                  bg: "blue.500",
                                }}
                                type="submit"
                              >
                                Kirim
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </Fragment>
              )}
            </Fragment>
          )}
        </Box>
      </Box>
    </Fragment>
  );
}

export default Submission;
