import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Tag,
  Input,
  Flex,
} from "@chakra-ui/react";
import { ImCross, ImCheckmark } from "react-icons/im";
import { apiPos } from "../../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../../envs";
import ModalUser from "./ModalUser";
import ModalReject from "./ModalReject";
import { chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import GlobalFilter from "../../../helper/globalFilter";
import "../../../containers/table/table.css";
import moneyFormat from "../../../helper/helper";

function UserListNotApprove() {
  const [user, setUser] = useState([]);
  const [userUpdate, setUserUpdate] = useState({ userId: null });
  const [userDetail, setUserDetail] = useState(null);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [alertMassageReject, setalertMassageReject] = useState(false);
  const [alertMassageFailedReject, setalertMassageFailedReject] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenReject, onOpen: onOpenReject, onClose: onCloseReject } = useDisclosure();
  const [load, setLoad] = useState(false);

  const verifikasiAPI = async () => {
    // alert("ya");
    setLoad(true);
    await apiPos
      .patch(`/v1/user/approve`, userUpdate, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    onClose();
    getAPI();
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
  };

  const getAPI = () => {
    apiPos
      .get(`/v1/user/all`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setUser(res.data.data.user.filter((item) => item.isVerified == 0 && item.partnerregistration.length > 0));
        // console.log(res.data.data.user.filter((item) => item.isVerified == 0 && item.partnerregistration.length > 0));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openModal = (id) => {
    //request API for modal
    //api to show detail user
    apiPos
      .get(`/v1/user/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setUserDetail(res.data.data.user);
        setUserUpdate({ userId: `${res.data.data.user.id}` });
        // console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });

    onOpen();
  };

  const openModalReject = (id) => {
    // console.log("cekss", id);
    onOpenReject();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  //REACT TABLE START
  const userData = React.useMemo(() => [...user], [user]);

  // const isverif = (item) => {
  //   // return (item)?<ImCheckmark />:<ImCross />;
  //   console.log("coba",item);
  // }
  const userColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: (props) => <Box w="120px">{moneyFormat(props.value)}</Box>,
      },
      {
        Header: "Rating",
        accessor: "ratingTotal",
        Cell: (props) => {
          return props.row.original.projectCount > 0 ? (props.row.original.ratingTotal / props.row.original.projectCount).toString().substr(0, 4) : "0";
        },
      },
      // {
      //   Header: "Rating Count",
      //   accessor: "ratingCount",
      // },
      {
        Header: "Project",
        accessor: "projectCount",
      },
      {
        Header: "Status",
        accessor: "partnerregistration",
        Cell: (props) => (props.value.length > 0 ? <Tag colorScheme="red">Mitra</Tag> : <Tag colorScheme="teal">Client</Tag>),
      },
      {
        Header: "Verified",
        accessor: "isVerified",
        Cell: (props) => (props.value ? <ImCheckmark /> : <ImCross />),
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Detail",
        Header: "Detail",
        Cell: ({ row }) => (
          <Button
            colorScheme="blue"
            onClick={() => {
              openModal(row.original.id);
              // console.log(row);
            }}
          >
            Detail
          </Button>
        ),
      },
    ]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state, page, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, gotoPage, pageCount } = useTable(
    { columns: userColumns, data: userData },
    useGlobalFilter,
    useSortBy,
    tableHooks,
    usePagination
  );

  const { pageIndex } = state;
  //REACT TABLE END

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>User List Not Approved</Center>
            </Heading>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              User Verification Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              User Verification Failed
            </Alert>
          )}
          {alertMassageReject && (
            <Alert status="success">
              <AlertIcon />
              User Reject Success
            </Alert>
          )}
          {alertMassageFailedReject && (
            <Alert status="error">
              <AlertIcon />
              User Reject Failed
            </Alert>
          )}
          <Fragment>
            <br />
            <Box w="40%" mb={2} display="flex" alignItems="center">
              <Box mr={2}>Search : </Box> <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
            </Box>
            <table {...getTableProps()}>
              <thead>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {
                              // Render the header
                              column.render("Header")
                            }
                            <span>{column.isSorted ? column.isSortedDesc ? <TriangleDownIcon aria-label="sorted descending" /> : <TriangleUpIcon aria-label="sorted ascending" /> : ""}</span>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <br />
            <Box>
              <chakra.span>
                page {""}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}{" "}
                </strong>
              </chakra.span>
              <Button onClick={() => gotoPage(0)} size={"sm"} disabled={!canPreviousPage}>
                {"<<"}
              </Button>{" "}
              <Button onClick={() => previousPage()} size={"sm"} disabled={!canPreviousPage}>
                Previous
              </Button>{" "}
              <Button onClick={() => nextPage()} size={"sm"} disabled={!canNextPage}>
                Next
              </Button>{" "}
              <Button onClick={() => gotoPage(pageCount - 1)} size={"sm"} disabled={!canNextPage}>
                {">>"}
              </Button>{" "}
              <chakra.span>
                Go to page: {""}
                <Input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(pageNumber);
                  }}
                  style={{ width: "50px" }}
                />
              </chakra.span>
            </Box>

            {/* MODAL START */}
            <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
              <form
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   verifikasiAPI();
              //   getAPI();
              // }}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Detail {userDetail && userDetail.name}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <ModalUser detail={userDetail} />
                  </ModalBody>
                  {userDetail && userDetail.partnerregistration.length > 0 && userDetail.isVerified == 0 ? (
                    <ModalFooter>
                      {/* <Button colorScheme="blue" mr={3} onClick={onClose} size="sm">
                        Close
                      </Button> */}
                      <Button
                        onClick={(e) => {
                          e.preventDefault();

                          openModalReject(userUpdate.userId);
                        }}
                        variant="solid"
                        mr={3}
                        colorScheme="red"
                        type="submit"
                        size="sm"
                      >
                        {load ? (
                          <Stack direction={"row"} spacing={4} align={"center"}>
                            <Spinner />
                            <Text>Loading...</Text>
                          </Stack>
                        ) : (
                          "Reject"
                        )}
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          verifikasiAPI();
                          getAPI();
                        }}
                        variant="solid"
                        colorScheme="green"
                        type="submit"
                        size="sm"
                      >
                        {load ? (
                          <Stack direction={"row"} spacing={4} align={"center"}>
                            <Spinner />
                            <Text>Loading...</Text>
                          </Stack>
                        ) : (
                          "Approve"
                        )}
                      </Button>
                    </ModalFooter>
                  ) : (
                    ""
                  )}
                </ModalContent>
              </form>
            </Modal>
            {/* MODAL END */}
            {/* MODAL Reject START */}
            <Modal onClose={onCloseReject} isOpen={isOpenReject} motionPreset="slideInBottom">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Pesan Reject</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <ModalReject userID={userUpdate.userId} setalertMassageFailed={setalertMassageFailedReject} setalertMassage={setalertMassageReject} onClose={onClose} onCloseReject={onCloseReject} getAPI={getAPI} />
                </ModalBody>
              </ModalContent>
            </Modal>
            {/* MODAL Reject END */}
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default UserListNotApprove;
