import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Center,
  Tag,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  HStack,
  VStack,
  StackDivider,
  Text,
  Divider,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import { Redirect } from "react-router-dom";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Stack, Checkbox } from "@chakra-ui/react";
import { boolean } from "yup";
import { Image } from "@chakra-ui/react";
import Icon from "../../img/Icon.png";
import moment from "moment";

const ModalUser = ({ handleChange, detailProject }) => {
  const toast = useToast();
  const [data, setData] = useState();
  const [dataPayment, setDataPayment] = useState();

  // handle change form
  const handleOnChange = (e) => {
    // kirim state ke parent
    handleChange(e);
  };

  useEffect(() => {
    setData(detailProject.timeline);
    // console.log(detailProject);
    setDataPayment(detailProject.payment);
  }, [detailProject]);

  // const getCallback = (paymentId) => {
  //   alert(paymentId);
  //   apiPos
  //     .get(`/v1/payment/checkexpired/${paymentId}`, {
  //       headers: {
  //         Authorization: `Bearer ${Cookies.get("token")}`,
  //         AppNumber: envs.appNumber,
  //       },
  //     })
  //     .then((res) => {
  //       console.log("gmns", res);
  //     })
  //     .catch((e) => {
  //       console.log("nih", e);
  //     });
  // };

  const getCallback = async (paymentId) => {
    try {
      const responseApi = await apiPos.get(`/v1/payment/checkexpired/${paymentId}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      });

      toast({
        title: "Submit Berhasil",
        description: responseApi.data,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "ERROR",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Fragment>
      <Tag variant="solid" colorScheme="teal">
        Metode Pembayaran: {dataPayment && dataPayment[0].provider}
      </Tag>
      {data &&
        data.map((item, index) => (
          <Fragment key={index}>
            <Text>{dataPayment && dataPayment.provider}</Text>
            <VStack direction="row" h="100px" p={4}>
              <Text>{moment(new Date(item.createdAt)).add(7, "h").utc().format("MMMM Do YYYY HH:mm:ss ")} WIB</Text>
              <Text>{item.message}</Text>

              <Text> {item.user && `oleh ${item.user.name}`}</Text>
            </VStack>
            <Center height="50px"></Center>
            <Divider borderColor="red" />
          </Fragment>
        ))}
      {dataPayment && (
        <Button size="sm" colorScheme={"yellow"} variant="solid" onClick={() => getCallback(dataPayment[0].id)}>
          Check Callback
        </Button>
      )}
    </Fragment>
  );
};

export default ModalUser;
