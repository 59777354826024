import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue, Stack, Heading, HStack } from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  StarIcon,
  Center,
  Formik,
  Form,
  Field,
  Button,
  Flex,
  Text,
  Link,
  Checkbox,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Select,
  boxShadow,
  Divider,
  Spacer,
  Tag,
  Container,
  WrapItem,
  Wrap,
  Textarea,
  MdOutlineEmail,
  InputLeftElement,
  InputGroup,
  BsPerson,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import * as Yup from "yup";
import axios from "axios";
import envs from "../../envs";
import FormTicket from "./FormTicket";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import userEvent from "@testing-library/user-event";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { TiPencil } from "react-icons/ti";
import { AiFillCheckCircle } from "react-icons/ai";

function ViewTicket() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const TcId = searchParams.get("tcId");
  const TrId = searchParams.get("trId");
  const userId = searchParams.get("uId");

  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [form, setForm] = useState(false);
  const [statusProject, setStatus] = useState("");
  const [projectName, setProjectName] = useState("");
  const [partnerRegis, setPartnerRegis] = useState(null);
  const [ticketReplay, setTicketReplay] = useState([]);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [load, setLoad] = useState(false);

  let initialValues = {
    ticketroomId: TrId,
    ticket: {
      userId: Cookies.get("userID"),
      message: "",
    },
  };
  //state for title and content
  const [collection, setCollection] = useState(initialValues);

  const inputOnChange = (e) => {
    setCollection((prevState) => ({
      ...prevState,
      ticket: {
        ...prevState.ticket,
        [e.target.name]: e.target.value,
      },
    }));
  };

  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState(null);

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    setLoad(true);
    var bodyFormData = new FormData();
    bodyFormData.append("json", JSON.stringify(collection));
    bodyFormData.append("attachment", selectedFile);

    // Display the key/value pairs
    // for (var pair of bodyFormData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]);
    // }

    await apiPos
      .post(`/v1/ticket/reply/${TrId}`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoad(false);
        console.log(res);
      })
      .catch((e) => {
        setLoad(false);
        console.log(e);
      });
    getAPI();
    toggleForm();
    setCollection(initialValues);
  };

  const closeTicket = () => {
    //close ticket
    apiPos
      .put(
        `/v1/ticket/${TrId}`,
        { status: "Closed" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            AppNumber: envs.appNumber,
          },
        }
      )
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.log(e);
        setalertMassageFailed(true);
      });
    getAPI();
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    window.scrollTo(0, 0); //scroll to top page
  };

  const toggleForm = () => {
    setForm(!form);
  };

  const getAPI = () => {
    //get ticket
    apiPos
      .get(`/v1/ticket?session=false&ticketcategoryId=${TcId}&ticketroomId=${TrId}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setTicketReplay(res.data.data.tickets[0].tickets);
        setTitle(res.data.data.tickets[0].title);
        setCategory(res.data.data.tickets[0].ticketCategory.categoryName);
        setProjectName(res.data.data.tickets[0].project);
        setStatus(res.data.data.tickets[0].status);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAPIUSER = (e) => {
    //get user
    apiPos
      .get(`/v1/user/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setPartnerRegis(res.data.data.user.partnerregistration);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAPI();
    getAPIUSER(userId);
  }, []);

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
            <Center>View ticket</Center>
          </Heading>
          <br />
          <Stack spacing={3}>
            {/* <Flex >
                <Alert status="info"> 
                  <Tag variant='solid' colorScheme='teal' mr={2}>{category}</Tag> 
                    <Text fontSize="md" fontWeight="bold">{title} 
                    
                  </Text>
                  
                </Alert>
              <Spacer />
              <Tag size={'lg'}>{partnerRegis && (partnerRegis.length > 0)?'Mitra':'Client'}</Tag>
            </Flex> */}
            {alertMassage && (
              <Alert status="success">
                <AlertIcon />
                Ticket Closed Success
              </Alert>
            )}
            {alertMassageFailed && (
              <Alert status="error">
                <AlertIcon />
                Ticket Closed Failed
              </Alert>
            )}
            <Flex>
              <Box>
                <Alert status="info">
                  <Tag variant="solid" colorScheme="teal" mr={2}>
                    {category}
                  </Tag>
                  <Text fontSize="md" fontWeight="bold">
                    {title}
                  </Text>
                </Alert>
              </Box>
              <Spacer />
              <Box p="3">
                <HStack spacing="24px">
                  <Box>
                    {partnerRegis && partnerRegis.length > 0 ? (
                      <Tag size={"lg"} colorScheme="red">
                        Mitra
                      </Tag>
                    ) : (
                      <Tag size={"lg"} colorScheme="cyan">
                        Client
                      </Tag>
                    )}
                  </Box>
                  <Box>
                    <Tag size={"lg"} colorScheme="cyan">
                      Status: {statusProject}
                    </Tag>
                  </Box>
                </HStack>
              </Box>
            </Flex>
            {projectName && (
              <Alert status="success" variant="top-accent">
                <AlertIcon />
                Project: {projectName.name}
              </Alert>
            )}
          </Stack>
          <br />
          <Box mb={4}>
            <Button onClick={toggleForm} width="100%" size="md" colorScheme="blue" _hover={{ boxShadow: "md" }}>
              <TiPencil />
              &nbsp;&nbsp;Balas
            </Button>
          </Box>
          {form && (
            <Box bg="#f7fafc" p={2} mb={2} borderRadius="lg">
              <Box m={8} color="#0B0E3F">
                <VStack spacing={5}>
                  <FormControl id="pesan">
                    <FormLabel>Pesan</FormLabel>
                    <Textarea
                      borderColor="gray.300"
                      _hover={{
                        borderRadius: "gray.300",
                      }}
                      placeholder="Ketik Balasan Disini...."
                      onChange={inputOnChange}
                      name="message"
                      value={collection.ticket.message}
                    />
                  </FormControl>
                  <FormControl id="name">
                    <FormLabel>Lampiran</FormLabel>
                    <Input variant="filled" type="file" size="sm" onChange={onFileChange} />
                  </FormControl>
                  <Box>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      variant="solid"
                      bg="#0D74FF"
                      color="white"
                      _hover={{}}
                    >
                      {load ? (
                        <Stack direction={"row"} spacing={4} align={"center"}>
                          <Spinner />
                          <Text>Loading...</Text>
                        </Stack>
                      ) : (
                        "Kirim Balasan"
                      )}
                    </Button>
                  </Box>
                </VStack>
              </Box>
            </Box>
          )}

          {ticketReplay &&
            ticketReplay.map((item, i) => {
              console.log("hows", item.ticketroomId);
              console.log("item", item);
              return (
                <Box key={i} mb={4}>
                  <Box
                    p={5}
                    shadow="md"
                    borderWidth="5px"
                    flex="1"
                    borderRadius="xl"
                    // bg={useColorModeValue('white', 'gray.700')}
                  >
                    <Flex>
                      {/* <Box p='2' direction={'row'} > */}
                      <Stack direction={"row"} spacing={4} align={"center"}>
                        <Text fontSize="md" fontWeight="extrabold">
                          {item.user.name}
                        </Text>
                        {/* </Box> */}
                      </Stack>
                      <Spacer />
                      <Box p="2">
                        <Text fontSize="sm"> {moment(item.createdAtt).utc().format("LLLL")}</Text>
                      </Box>
                    </Flex>
                    <Divider />
                    {item.message}
                    <Divider />
                    {JSON.parse(item.attachment) && JSON.parse(item.attachment).length > 0 ? (
                      <>
                        Attachment:{" "}
                        <Link href={`${envs.imageUrl}/${JSON.parse(item.attachment)[0].filePath}`} isExternal>
                          {JSON.parse(item.attachment)[0].originalName} <ExternalLinkIcon mx="2px" />
                        </Link>
                      </>
                    ) : (
                      "No Attachment"
                    )}
                  </Box>
                </Box>
              );
            })}
          <Box>
            <Center h="100px">
              <Button onClick={closeTicket} colorScheme="green" variant="outline">
                <AiFillCheckCircle />
                &nbsp;&nbsp; TUTUP PENGADUAN
              </Button>
            </Center>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default ViewTicket;
