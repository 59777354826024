import React, { Fragment, useState, useEffect, useRef } from "react";
import { Box, useColorModeValue, Stack, Heading, Badge, Image } from "@chakra-ui/react";
// import { useS3Uploader } from "use-s3-uploader";
// import AWS from "aws-sdk";
// import envs from "../../envs";
// const spacesEndpoint = new AWS.Endpoint(envs.S3_URL);

function Dashboard() {
  // const s3 = new AWS.S3({
  //   endpoint: spacesEndpoint,
  //   credentials: {
  //     accessKeyId: envs.S3_KEY,
  //     secretAccessKey: envs.S3_SECRET,
  //   },
  // });
  // const inputRef = useRef(null);

  // const getSignedUrl = (file, next) => {
  //   next({
  //     signedUrl: "https://example.amazonaws.com/test.jpg?x-amz-acl=.......",
  //   });
  // };

  // const onUploadStart = (file, next) => {
  //   next(file);
  // };

  // const onProgress = (percent, status, file) => {
  //   console.log("onProgress", percent, status, file);
  // };

  // const onError = (error, file) => {
  //   console.log(error, file);
  // };

  // const onFinish = (signResult, file) => {
  //   console.log(signResult, file);
  // };

  // useS3Uploader(
  //   {
  //     getSignedUrl: getSignedUrl,
  //     onUploadStart: onUploadStart,
  //     onProgress: onProgress,
  //     onError: onError,
  //     onFinish: onFinish,
  //     accept: "image/*",
  //     uploadRequestHeaders: {
  //       "x-amz-acl": "public-read",
  //     },
  //     contentDisposition: "auto",
  //   },
  //   inputRef
  // );
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Stack spacing={6}>
          <Heading as="h1" size="lg" isTruncated>
            Dashboard
          </Heading>
          {/* <div>
            <input type="file" ref={inputRef} multiple={true} />
          </div> */}
        </Stack>
      </Box>
    </Fragment>
  );
}

export default Dashboard;
