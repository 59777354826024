import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {  useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select
  } from "@chakra-ui/react"
  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";
  import { apiPos } from "../../utils/axios";
  import Cookies from "js-cookie";
  import { CKEditor } from '@ckeditor/ckeditor5-react';
  import Editor from 'ckeditor5-custom-build/build/ckeditor';
  import {ckEditorRemoveTags,editorConfiguration} from "../../helper/CKeditorHelper"

function CreatFormField() {

  const history = useHistory();
  const [text,setText]=useState("");
  const [categories,setCategories]=useState([]);


  let initialValues = {
    versionCode: "",
    tnc: "",
   
  };
    //state for title and content
  const [collection,setCollection]=useState(initialValues)

  let [load, setLoad] = useState(false);


  const handleSubmit = async () => {
    setLoad(true)

    await apiPos.post(`/v1/tnc/`,collection ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
       
      },
    })
    .then((res) => {
      setLoad(false);
      setTimeout(function(){ history.replace("/termcondition") }, 2000);
     
      console.log(res)
    })
    .catch((e) => {
      setLoad(false);
      console.log(e);
    }); 
    
      // console.log(collection)
  };


  const inputOnChange = (e) => {

    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };


  const handleOnChange = (event, editor)=>{
    
    const data = editor.getData();
    setText(data);

}

useEffect(() => {

  //set content value every time text changes
  setCollection({
    ...collection,
    //"content": text.replace(/^([^\.]*\.[^>]*)(.*)$/g, "$1/$2")
    "tnc": ckEditorRemoveTags(text)
  });
}, [text]);


  const{toggleColorMode}= useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Create Term & Condition</Center>
            </Heading>
            </Stack>
            <br />
            <Box as={'form'}  >
              <Stack spacing={4} >
                <FormControl id="versionCode" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="11%">
                  <FormLabel>Version Code</FormLabel>
                </Box>
                <Box w="89%">
                  <Input type="text"  placeholder="...." name="versionCode" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="target" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="11%">
                  <FormLabel>Target</FormLabel>
                </Box>
                <Box w="89%">
                <Select placeholder="Select Target" name="target"onChange={inputOnChange} >
                    <option value="client">Client</option>
                    <option value="partner">Partner/Mitra</option>
                  </Select>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="tnc" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="11%">
                  <FormLabel>Term & Condition</FormLabel>
                  </Box>
                  <Box w="89%">
                  <p>*upload image from url only</p>
                    <CKEditor
                        editor={ Editor }
                        config={ editorConfiguration }
                        data={text}
                        onChange={handleOnChange}
                      
                    />
                  </Box>
                </Stack>
                </FormControl>
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
              </Stack>
            </Box>            
          </Box>
      </Box>
    </Fragment>
  );
}

export default CreatFormField;