import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Tag,
  Input,
  Flex,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import { ImCross, ImCheckmark } from "react-icons/im";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import { chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import GlobalFilter from "../../helper/globalFilter";
import "../../containers/table/table.css";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";

function SearchList() {
  const toast = useToast();
  const history = useHistory();
  const [suggestion, setSuggestion] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [load, setLoad] = useState(false);

  const getAPI = () => {
    apiPos
      .get(`/v1/search/suggestion`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setSuggestion(res.data.data.queries);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //api to show table all TAG
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  // REACT TABLE START
  const suggestionData = React.useMemo(() => [...suggestion], [suggestion]);

  const suggestionColumns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Label",
        accessor: "label",
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Detail",
        Header: "Detail",
        Cell: ({ row }) => (
          <>
            <HStack spacing="24px">
              <Box w="40px" h="40px">
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => {
                    if (window.confirm("Are you sure you wish to delete this search?")) deleteSearch(row.original.id);
                  }}
                >
                  Delete
                </Button>
              </Box>
            </HStack>
          </>
        ),
      },
    ]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state, page, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, gotoPage, pageCount } = useTable(
    { columns: suggestionColumns, data: suggestionData },
    useGlobalFilter,
    useSortBy,
    tableHooks,
    usePagination
  );

  const { pageIndex } = state;
  // REACT TABLE END

  //DELETE Search  START
  const deleteSearch = async (e) => {
    setLoad(true);
    await apiPos
      .delete(`/v1/search/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        toast({
          title: "Delete Berhasil",
          description: "Delete Search Suggestion Berhasil",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e) => {
        // console.error(e.response.data);
        toast({
          title: "Delete Search Suggestion Gagal",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    setLoad(false);

    getAPI();
  };
  //DELETE Search END
  return (
    <Fragment>
      {" "}
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Search Suggestion List</Center>
            </Heading>
          </Stack>

          <Fragment>
            <br />
            <Box mb={2}>
              <Flex>
                <Box mr={2}>Search : </Box> <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
                <Spacer />
                <Box>
                  <Button colorScheme="blue" size="md" onClick={() => history.replace("/createsearch")}>
                    + Add
                  </Button>
                </Box>
              </Flex>
            </Box>

            <table {...getTableProps()}>
              <thead>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {
                              // Render the header
                              column.render("Header")
                            }
                            <span>{column.isSorted ? column.isSortedDesc ? <TriangleDownIcon aria-label="sorted descending" /> : <TriangleUpIcon aria-label="sorted ascending" /> : ""}</span>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <br />
            <Box>
              <chakra.span>
                page {""}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}{" "}
                </strong>
              </chakra.span>
              <Button onClick={() => gotoPage(0)} size={"sm"} disabled={!canPreviousPage}>
                {"<<"}
              </Button>{" "}
              <Button onClick={() => previousPage()} size={"sm"} disabled={!canPreviousPage}>
                Previous
              </Button>{" "}
              <Button onClick={() => nextPage()} size={"sm"} disabled={!canNextPage}>
                Next
              </Button>{" "}
              <Button onClick={() => gotoPage(pageCount - 1)} size={"sm"} disabled={!canNextPage}>
                {">>"}
              </Button>{" "}
              <chakra.span>
                Go to page: {""}
                <Input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(pageNumber);
                  }}
                  style={{ width: "50px" }}
                />
              </chakra.span>
            </Box>
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default SearchList;
