import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {  useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select
  } from "@chakra-ui/react"
  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";
  import { apiPos } from "../../utils/axios";
  import Cookies from "js-cookie";


function CreatYoutube() {

  const history = useHistory();
  const [text,setText]=useState("");
  const [categories,setCategories]=useState([]);

    // Initially, no file is selected 
    const[selectedFile,setSelectedFile]=useState(null)

  let initialValues = {
    url: "",
    ytId:""
  };
    //state for title and content
  const [collection,setCollection]=useState(initialValues)

  let [load, setLoad] = useState(false);


  const handleSubmit = async () => {
    setLoad(true)

    await apiPos.post(`/v1/yt/`,collection ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
       
      },
    })
    .then((res) => {
      setLoad(false);
      setTimeout(function(){ history.replace("/youtubelist") }, 2000);
     
      console.log(res)
    })
    .catch((e) => {
      setLoad(false);
      console.log(e);
    }); 
    

  };

  // On file select (from the pop up) 
  const onFileChange = event => { 
    // Update the state 
     setSelectedFile(event.target.files[0]);
     console.log(event.target.files[0])
  }; 


     
  const getAPI=()=>{
    apiPos
    .get(`/v1/yt`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
      },
    })
    .then((res) => {
      console.log(res);
  
    })
    .catch((e) => {
      console.log(e);
    });
  }




  const inputOnChange = (e) => {

    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };



  useEffect(() => {
    getAPI()
  }, []);

  const handleOnChange = (event, editor)=>{
    
      const data = editor.getData();
      setText(data);
  
  }

  const{toggleColorMode}= useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Add Youtube Video</Center>
            </Heading>
            </Stack>
            <br />
            <Box as={'form'}  >
              <Stack spacing={4} >
                <FormControl id="url" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Url Video</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="example: https://www.youtube.com/watch?v=0kX6FTN5OZ4" name="url" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="ytId" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Youtube id</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder={`example youtube id insert between (*): https://www.youtube.com/watch?v=*0kX6FTN5OZ4*`} name="ytId" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
              </Stack>
            </Box>            
          </Box>
      </Box>
    </Fragment>
  );
}

export default CreatYoutube;