import React, { Fragment, useState, useEffect } from "react";
import {
  SimpleGrid,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Tag,
  Input,
  Flex,
  Spacer,
  HStack,
  Image,
  Badge,
  AspectRatio,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { ImCross, ImCheckmark } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import ModalTag from "./ModalTag";
import { chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import GlobalFilter from "../../helper/globalFilter";
import "../../containers/table/table.css";
import { useHistory } from "react-router-dom";

function Portfolio() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const portoId = searchParams.get("id");

  const history = useHistory();
  const [kasiDetail, setKasiDetail] = useState([]);
  const [tagUpdateId, setTagUpdateId] = useState("");
  // const [tagDetail, setTagDetail] = useState([]);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [alertMassageUpdate, setalertMassageUpdate] = useState(false);
  const [alertMassageUpdateFailed, setalertMassageUpdateFailed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [load, setLoad] = useState(false);

  let initialValues = {
    name: "",
    kasiId: "",
  };

  const [collection, setCollection] = useState(initialValues);

  const submitUpdate = async (e) => {
    console.log("ini e", e);
    console.log("ini id", tagUpdateId);
    setLoad(true);
    await apiPos
      .patch(`/v1/kasitag/${tagUpdateId}`, collection, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassageUpdate(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageUpdateFailed(true);
      });
    setLoad(false);
    onClose();
    getAPI();
    setTimeout(function () {
      setalertMassageUpdate(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageUpdateFailed(false);
    }, 3000);
  };

  // handle on change untuk di kirim ke component ModalTag
  const handleOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
    // setTagDetail({
    //   ...tagDetail,
    //   [e.target.name]: e.target.value,
    // });
  };

  const getAPI = () => {
    apiPos
      .get(`/v1/userkasi/${portoId}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        console.log("detail", res);
        setKasiDetail(res.data.data.userkasi.portfolio);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openModal = (id, kasiId) => {
    console.log("ini ID", id);
    console.log("ini kasiId", kasiId);
    setCollection(initialValues);
    setTagUpdateId(id);
    //request API for modal
    //api to show detail user
    apiPos
      .get(`/v1/kasitag?id=${id}&kasiId=${kasiId}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        if (res.data.data.kasitags[0] == null) {
          setCollection(initialValues);
          // setTagDetail(initialValues)
        } else {
          // setTagDetail(res.data.data.kasitags[0])
          setCollection(res.data.data.kasitags[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    onOpen();
  };

  //api to show table all TAG
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  //DELETE TAG  START
  const deletePortofolio = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);
    await apiPos
      .delete(`/v1/portfolio/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };
  //DELETE TAG END

  return (
    <Fragment>
      {" "}
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Portfolio Detail</Center>
            </Heading>
          </Stack>
          <Box mb={2}>
            <Flex>
              <Spacer />
              <Box>
                <Button colorScheme="blue" size="md" onClick={() => history.replace(`/createportfolio?id=${portoId}`)}>
                  + Tambah
                </Button>
              </Box>
            </Flex>
          </Box>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Portfolio Delete Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Portfolio Delete Failed
            </Alert>
          )}
          {alertMassageUpdate && (
            <Alert status="success">
              <AlertIcon />
              Portfolio Update Success
            </Alert>
          )}
          {alertMassageUpdateFailed && (
            <Alert status="error">
              <AlertIcon />
              Portfolio Update Failed
            </Alert>
          )}

          {kasiDetail.length > 0 ? (
            <Fragment>
              <SimpleGrid columns={[1, 2, 3]} spacing="40px">
                {kasiDetail.map((item, i) => {
                  console.log("cek", item);
                  return (
                    <Fragment key={i}>
                      <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
                        {(() => {
                          if (item.portfolioimage[0].fileType == "video/mp4") {
                            return (
                              <Box
                                as="video"
                                controls
                                src={`${envs.imageUrl}/${item.portfolioimage[0].image}`}
                                alt="video portfolio"
                                sx={{
                                  aspectRatio: "16/9",
                                }}
                              />
                            );
                          } else {
                            return <Image boxSize="300px" src={`${envs.imageUrl}/${item.portfolioimage[0].image}`} alt="portfolio mitra" />;
                          }
                        })()}
                        <Box p="6">
                          <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
                            {item.name}
                          </Box>
                          <Box>{item.description}</Box>
                          <Box>
                            <Flex>
                              <Spacer />
                              <Box>
                                <Button
                                  colorScheme="red"
                                  size="sm"
                                  onClick={() => {
                                    if (window.confirm("Are you sure you wish to delete this portfolio?")) deletePortofolio(item.id);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Box>
                            </Flex>
                          </Box>
                        </Box>
                      </Box>
                    </Fragment>
                  );
                })}
              </SimpleGrid>
            </Fragment>
          ) : (
            "belum ada portfolio"
          )}

          <Fragment>
            {/* MODAL START */}
            <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitUpdate();
                  getAPI();
                }}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Detail</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <ModalTag handleChange={handleOnChange} detail={collection} />
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                      Close
                    </Button>
                    <Button variant="solid" colorScheme="green" type="submit">
                      {load ? (
                        <Stack direction={"row"} spacing={4} align={"center"}>
                          <Spinner />
                          <Text>Loading...</Text>
                        </Stack>
                      ) : (
                        "Simpan"
                      )}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
            {/* MODAL END */}
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Portfolio;
