import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select,
    boxShadow
  } from "@chakra-ui/react"
  import { useFormik } from "formik";

  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";
  import FormSubClass from "./FormSubClass";
 
function AddSubClass() {

  const [contents, setContent] = useState([]);
  const [contentCount, setContentCount] = useState(1);

  const addContents = () => {
    // create an array
    setContent(contents => [...contents, <FormSubClass counts={contentCount} />]);
    setContentCount(prevCount => prevCount + 1);
    console.log("dddd", contents);
  };

  useEffect(() => {
    addContents()
  }, []);

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Sub Class</Center>
            </Heading>
            <br />
            <Alert status="info">
              <AlertIcon />
              Here's where you can add Sub Classes
            </Alert>
            <br />
            {contents.map((item, i) => (
              <Box p={4}key={i}>{item}</Box>
            ))}
            
          </Box>
          <Button 
            onClick={addContents} 
            rounded="full"
            size="lg" 
            colorScheme="blue" 
            _hover={{boxShadow:'lg'}}
            >+</Button>
      </Box>
    </Fragment>
  );
}

export default AddSubClass;