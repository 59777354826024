import React, { Fragment, useState, useEffect } from "react";
import {
  Badge,
  Flex,
  Spacer,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Image,
  AspectRatio,
} from "@chakra-ui/react";
import { ImCross, ImCheckmark } from "react-icons/im";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";

function CountMitra() {
  const [userRequest, setUserRequest] = useState(null);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [countMitra, setCountMitra] = useState([]);
  const [load, setLoad] = useState(false);
  const [bodyApi, setBodyApi] = useState(null); //nextnya jika sudah ada api untuk reject, digunakan untuk mengirim alasan reject

  const getAPI = () => {
    apiPos
      .get(`/v1/kasi/count/talent`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setCountMitra(res.data.data);
        console.log("ini mitra", res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Jumlah Mitra</Center>
            </Heading>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Rate Approval Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Rate Approval Failed
            </Alert>
          )}
          <Fragment>
            <br />
            <SimpleGrid columns={[1, 2, 3]} spacing="40px">
              {countMitra.map((item, i) => {
                console.log("cek", item);
                return (
                  <Fragment key={i}>
                    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
                      <Center m={4}>
                        <Image boxSize="100px" src={`${envs.imageUrl}/${item.kasi.icon}`} alt="portfolio mitra" />
                      </Center>
                      {/* <Image src={`${envs.imageUrl}/${item.kasi.icon}`} alt="Identity Photo" /> */}
                      <Box p="6" bg="gray.500">
                        <Box color="white" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase" ml="2">
                          <Flex>
                            <Box p="4">{item.kasi.name}</Box>
                            <Spacer />
                            <Box p="4">
                              {" "}
                              <Badge borderRadius="full" px="2">
                                {item.total}
                              </Badge>
                            </Box>
                          </Flex>
                        </Box>
                        <Box></Box>
                      </Box>
                    </Box>
                  </Fragment>
                );
              })}
            </SimpleGrid>
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CountMitra;
