import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue, Stack, Heading, HStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  StarIcon,
  Center,
  Formik,
  Form,
  Field,
  Button,
  Flex,
  Text,
  Link,
  Checkbox,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import envs from "../../envs";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import AWS from "aws-sdk";

// Aws Setting start
const spacesEndpoint = new AWS.Endpoint(envs.S3_URL);
const S3 = new AWS.S3({
  endpoint: spacesEndpoint,
  credentials: {
    accessKeyId: envs.S3_KEY,
    secretAccessKey: envs.S3_SECRET,
  },
});
AWS.config.httpOptions.timeout = 0;
// Aws Setting end

function CreatPortfolio() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const portoId = searchParams.get("id");
  const history = useHistory();
  const [kasi, setKasi] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showprogress, setShowprogress] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState(null);

  let initialValues = {
    name: "",
    description: "",
    userkasiId: portoId,
    strifiles: [
      {
        uploadedPath: "",
        mimetype: "",
      },
    ],
  };
  //state for title and content
  const [collection, setCollection] = useState(initialValues);

  const [load, setLoad] = useState(false);

  const inputOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  const InputOnChangeForm2 = (e) => {
    setCollection((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
    handleSubmitS3(event.target.files[0]);
  };

  const handleSubmitS3 = async (data) => {
    try {
      setLoad(true);
      setShowprogress(true);
      const originalName = data.name;
      // if (selectedFile.name) {
      //   const originalName = selectedFile.name;
      // }
      const devPrefix = "DEVELOPMENT-";
      const pathName = `uploads/portfolioimg/${devPrefix}${Date.now()}-${originalName}`;
      const putObj = await S3.putObject({
        Bucket: envs.S3_BUCKET,
        Key: pathName,
        Body: data,
        ACL: "public-read",
        ContentType: data.type,
        ContentDisposition: "inline;",
      })
        .on("httpUploadProgress", function (evt) {
          setProgress(parseInt((evt.loaded * 100) / evt.total));
        })
        .promise();
      setCollection((prevState) => ({
        ...prevState,
        strifiles: [
          {
            ...prevState.strifiles[0],
            uploadedPath: pathName,
            mimetype: data.type,
          },
        ],
      }));
      setLoad(false);
      setShowprogress(false);
    } catch (error) {
      setLoad(false);
      alert(error.message);
    }
  };

  const handleSubmitForm = async () => {
    if (selectedFile == null || collection.name == "") {
      alert("Media atau Nama belum diisi");
    } else {
      setLoad(true);

      await apiPos
        .post(`/v1/portfolio/create`, collection, {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            AppNumber: envs.appNumber,
          },
        })
        .then((res) => {
          setalertMassage(true);
        })
        .catch((e) => {
          setalertMassageFailed(true);
          console.log("ini gagal", e);
        });
      setLoad(false);
      setCollection(initialValues);
      setSelectedFile(null);

      setTimeout(function () {
        setalertMassage(false);
        setalertMassageFailed(false);
      }, 4000);
      history.replace(`/portfoliodetail?id=${portoId}`);
    }
  };

  const { toggleColorMode } = useColorMode();

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Create Portfolio</Center>
            </Heading>
          </Stack>
          <br />
          <Box as={"form"} mt="8" p={4}>
            <Stack spacing={4}>
              <FormControl id="nama">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Nama</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="Input Nama..." name="name" onChange={InputOnChangeForm2} required />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="deskripsi">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Deskripsi</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="Input Deskripsi..." name="description" onChange={InputOnChangeForm2} required />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="media">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Media</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input variant="filled" type="file" size="sm" onChange={onFileChange} />
                  </Box>
                </Stack>
              </FormControl>

              <br />
              <Stack spacing={10}>
                {load ? (
                  <Button
                    bg={"blue.400"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                  >
                    <Stack direction={"row"} spacing={4} align={"center"}>
                      <Spinner />
                      {showprogress ? <Text>Uploaded {progress} %</Text> : <Text>Loading...</Text>}
                    </Stack>
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmitForm();
                    }}
                    bg={"blue.400"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    type="submit"
                  >
                    Kirim
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreatPortfolio;
