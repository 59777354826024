import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { Box } from "@chakra-ui/react";

import Navbar from "../containers/TheLayout/TheNavlink";

const PrivateRouter = ({ component: Component, ...rest }) => {
  return (
    <>
      <Navbar />
      <Box>
        <Route
          {...rest}
          render={(props) => {
            return Cookies.get("token") ? <Component {...props} /> : <Redirect to={{ pathname: "/login" }} />;
          }}
        />
      </Box>
    </>
  );
};

export default PrivateRouter;
