import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue, Stack, Heading, HStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  StarIcon,
  Center,
  Formik,
  Form,
  Field,
  Button,
  Flex,
  Text,
  Link,
  Checkbox,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { MultiSelect } from "react-multi-select-component";
import * as Yup from "yup";
import axios from "axios";
import envs from "../../envs";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";

function CreateKasi() {
  const history = useHistory();
  const [text, setText] = useState("");
  const [categories, setCategories] = useState([]);
  const [formfield, setFormField] = useState([]);
  const [selected, setSelected] = useState([]);

  // multiple selection
  const options = [];

  formfield.map((item, index) => options.push({ label: item.title, value: item.id, isRequired: false }));

  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState(null);

  let initialValues = {
    kasigroupId: "",
    name: "",
    description: "",
    // fieldIds: [{ fieldId: 0, isRequired: false }],
    fieldIds: [],
  };
  //state for title and content
  const [collection, setCollection] = useState(initialValues);

  let [load, setLoad] = useState(false);

  const handleSubmit = async () => {
    setLoad(true);
    var bodyFormData = new FormData();
    bodyFormData.append("kasigroupId", collection.kasigroupId);
    bodyFormData.append("name", collection.name);
    bodyFormData.append("description", collection.description);
    // bodyFormData.append("fieldRequired", collection.fieldRequired);
    let fieldIdsNew = selected.map((item) => ({ fieldId: item.value, isRequired: item.isRequired }));
    bodyFormData.append("fieldIds", JSON.stringify(fieldIdsNew));
    bodyFormData.append("icon", selectedFile);

    // Display the key/value pairs
    for (var pair of bodyFormData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    console.log("final", selected);

    await apiPos
      .post(`/v1/kasi/`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoad(false);
        setTimeout(function () {
          history.replace("/kasilist");
        }, 1000);
        console.log(res);
      })
      .catch((e) => {
        setLoad(false);
        console.log(e);
      });
  };

  const inputOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const inputOnChangeSelect = (e, item) => {
    console.log("inigmn", e.target.value);
    console.log("iniitem", item);

    let index = selected.findIndex((i) => i.value == item.value);
    console.log("index", index);

    selected[index].isRequired = e.target.value;
  };

  //api to show formfield
  const getAPI = () => {
    apiPos
      .get(`v1/formfield`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setFormField(res.data.data.formfields);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    // request to API
    getAPI();
  }, []);
  // useEffect(() => {
  //   console.log("col", collection);
  // }, [collection]);

  // useEffect(() => {
  //   console.log("selected", selected);
  // }, [selected]);

  const ChangeSelect = (e) => {
    // console.log("ini e", e);
    setSelected(e);
  };

  const { toggleColorMode } = useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Create Kasi (Available Services)</Center>
            </Heading>
          </Stack>
          <br />
          <Box as={"form"}>
            <Stack spacing={4}>
              <FormControl id="kasigroupId">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Kasi Group Id</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="...." name="kasigroupId" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="name">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Name</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="...." name="name" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="description">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Description</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="...." name="description" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl>
              <FormControl id="icon">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Icon</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input variant="filled" type="file" size="sm" onChange={onFileChange} />
                  </Box>
                </Stack>
              </FormControl>
              {/* <FormControl id="fieldRequired">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Field Required</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Input type="text" placeholder="...." name="fieldRequired" onChange={inputOnChange} />
                  </Box>
                </Stack>
              </FormControl> */}
              <FormControl id="fieldIds">
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>Field Required</FormLabel>
                  </Box>
                  <Box w="75%">
                    <pre>{JSON.stringify(selected)}</pre>
                    <MultiSelect options={options} value={selected} onChange={ChangeSelect} labelledBy="Select" />
                  </Box>
                </Stack>
              </FormControl>

              {selected.length > 0 &&
                selected.map((item, index) => (
                  <FormControl id="isRequired" key={index}>
                    <Stack direction={["row"]} spacing="24px">
                      <Box w="25%">
                        <FormLabel>isRequired {item.label}</FormLabel>
                      </Box>
                      <Box w="75%">
                        <Select placeholder="Select option" name="isRequired" onChange={(e) => inputOnChangeSelect(e, item)}>
                          <option value="true">Harus isi</option>
                          <option value="false">Tidak Harus</option>
                        </Select>
                      </Box>
                    </Stack>
                  </FormControl>
                ))}

              <br />
              <Stack spacing={10}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  {load ? (
                    <Stack direction={"row"} spacing={4} align={"center"}>
                      <Spinner />
                      <Text>Loading...</Text>
                    </Stack>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CreateKasi;
