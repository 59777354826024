import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, Input,Text,Center} from "@chakra-ui/react";
import QRcode from 'qrcode.react';

function SubmitTask() {
    const [qr, setQr] = useState('www.youtube.co.id');
    const handleChange = (event) => {
        setQr(event.target.value);
    };
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
      <Stack spacing={6}>
        <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
         <Center> Submit Task </Center>
        </Heading>
      </Stack>
      <br />
        <Stack spacing={3}>
            <Input onChange={handleChange}  value={qr} placeholder="Qr code" size="md" />
        </Stack>
        <br />
        <div>
                {
                    qr ?
                    <Center><QRcode 
                        id="myqr"
                        value={qr} 
                        size={320}
                        includeMargin={true}
                    />
                    </Center> :
                    <p>No QR code preview</p>
                }
        </div>
        <br />
        <div>
                {
                    qr ? 
                    <Center>   
                        <Center  bg="tomato" w="30%" p={1} color="white">
                        <Text style={{fontSize:18}}>
                        {qr}
                        </Text>
                        </Center>
                    </Center> :
                    ''
                }
            </div>
      </Box>
      
    </Fragment>
  );
}

export default SubmitTask;


// import {useColorModeValue,Box} from "@chakra-ui/react";
// import React, { Fragment, useState, useEffect } from "react";
// export default function BarcodeScanner (props) {

//   const [barcodeInputValue, updateBarcodeInputValue] = useState('')
  
//   const barcodeAutoFocus = () => {
//       document.getElementById("SearchbyScanning").focus()
//     }
  
//     const onChangeBarcode = (event) => {
//       updateBarcodeInputValue(event.target.value)
//     }
  
//     const onKeyPressBarcode = (event) => {
//       if (event.keyCode === 13) {
//         updateBarcodeInputValue(event.target.value)
//       }
//     }
  
//   return  (
//             <Fragment> 
//               <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4" >
             
//                   <input
//                     autoFocus={true}
//                     placeholder='Start Scanning'
//                     value={barcodeInputValue}
//                     onChange={onChangeBarcode}
//                     id='SearchbyScanning'
//                     className='SearchInput'
//                     onKeyDown={onKeyPressBarcode}
//                     onBlur={barcodeAutoFocus}
//                   />
              
//               </Box>
//               </Fragment> 
//   )
    
  
//   }