import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Flex,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";
import moment from "moment";

function SliderList() {
  const history = useHistory();
  const [slider, setSlider] = useState([]);
  const [load, setLoad] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const getAPI = () => {
    apiPos
      .get(`/v1/slider?period=all`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setSlider(res.data.data.sliders);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteSlider = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);

    await apiPos
      .delete(`/v1/slider/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>List Slider</Center>
            </Heading>
            <Flex>
              <Spacer />
              <Box>
                <Button colorScheme="blue" size="md" onClick={() => history.replace("/createslider")}>
                  + Add
                </Button>
              </Box>
            </Flex>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Delete Slider Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Delete Slider Failed
            </Alert>
          )}
          <Fragment>
            <br />
            <Table variant="striped" colorScheme="blackAlpha" size="sm" border="1px" borderColor="gray.200">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Title</Th>
                  <Th>Description</Th>
                  <Th>Start Date</Th>
                  <Th>End Date</Th>
                  <Th>Image</Th>
                  <Th>Page Parameter</Th>
                  <Th>Page To</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {slider &&
                  slider.map((item) => (
                    <Tr key={item.id}>
                      <Td>{item.id}</Td>
                      <Td>{item.title}</Td>
                      <Td>{item.description}</Td>
                      <Td>{moment(item.startDate).utc().format("LLLL")}</Td>
                      <Td>{moment(item.endDate).utc().format("LLLL")}</Td>
                      <Td>
                        <Link href={`${envs.imageUrl}/${item.image}`} isExternal>
                          Slider Uploaded <ExternalLinkIcon mx="4px" />
                        </Link>
                      </Td>
                      <Td>{item.pageParam}</Td>
                      <Td>{item.pageTo}</Td>
                      <Td>
                        {load ? (
                          <Button isLoading loadingText="Wait..."></Button>
                        ) : (
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => {
                              if (window.confirm("Are you sure you wish to delete this slider?")) deleteSlider(item.id);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default SliderList;
