import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Center,
  Input,
  Spinner,
  Text,
  Textarea,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import { Redirect } from "react-router-dom";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Stack, Checkbox } from "@chakra-ui/react";
import { boolean } from "yup";
import { Image } from "@chakra-ui/react";
import Icon from "../../img/Icon.png";

const ModalProjectCancel = ({ handleChange, detailUser }) => {
  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(false);

  // handle change form
  const handleOnChange = (e) => {
    // kirim state ke parent
    handleChange(e);
  };

  return (
    <Fragment>
      <Stack spacing={4}>
        <FormControl id="name">
          <Stack direction={["row"]} spacing="24px">
            <Box w="25%">
              <FormLabel>Pesan </FormLabel>
            </Box>
            <Box w="75%">
              <Textarea type="text" placeholder="...." name="message" onChange={handleOnChange} />
            </Box>
          </Stack>
        </FormControl>

        <br />
      </Stack>
    </Fragment>
  );
};

export default ModalProjectCancel;
