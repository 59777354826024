import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Flex,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import moment from "moment";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";

function TermConditionList() {
  const history = useHistory();
  const [term, setTerm] = useState([]);
  const [load, setLoad] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const getAPI = () => {
    apiPos
      .get(`/v1/tnc/all`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setTerm(res.data.data.tnc);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteTerm = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);

    await apiPos
      .delete(`/v1/term/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  return (
    <Fragment>
      {" "}
      {console.log(term)}
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Term & Condition List</Center>
            </Heading>
            <Flex>
              <Spacer />
              <Box>
                <Button colorScheme="blue" size="md" onClick={() => history.replace("/createtermcondition")}>
                  + Add
                </Button>
              </Box>
            </Flex>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Delete Term & Condition Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Delete Term & Condition Failed
            </Alert>
          )}
          <Fragment>
            <br />
            <Table variant="striped" colorScheme="blackAlpha" size="sm" border="1px" borderColor="gray.200">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>tnc</Th>
                  <Th>Version Code</Th>
                  <Th>Target</Th>
                  <Th>Created At</Th>
                  <Th>Updated At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {term &&
                  term.map((item) => {
                    return (
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>
                        <Td>{item.tnc.substring(0, 100)}</Td>
                        <Td>{item.versionCode}</Td>
                        <Td>{item.target}</Td>
                        <Td>{moment(item.createdAt).utc().format("LLLL")}</Td>
                        <Td>{moment(item.updatedAt).utc().format("LLLL")}</Td>

                        {/* <Td>
                    {load ?
                      <Button 
                      isLoading
                      loadingText="Wait..." >
                      </Button>
                    :<Button  colorScheme="red" size="sm" onClick={() => {if (window.confirm('Are you sure you wish to delete this item?')) deleteTerm(item.id)}}>Delete</Button>}
                    </Td> */}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default TermConditionList;
