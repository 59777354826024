const moneyFormat = (price, sign = "Rp ", delimeter = ".") => {
  const pieces = parseFloat(price).toFixed(2).split("");
  let ii = pieces.length - 3;
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, delimeter);
  }
  return sign + pieces.join("").substr(0, pieces.length - 3);
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === null) return "-- MB";
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export default moneyFormat;
export { formatBytes };
