import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Center,
  Input,
  Spinner,
  Text,
  Select,
  HStack,
} from "@chakra-ui/react";
import { MultiSelect } from "react-multi-select-component";
import { apiPos } from "../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../envs";
import { Redirect } from "react-router-dom";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Stack, Checkbox } from "@chakra-ui/react";
import { boolean } from "yup";
import { Image } from "@chakra-ui/react";

const ModalKasi = ({ handleChange, handleChangeSelected, form, onFileChange, chooseFields }) => {
  const [selected, setSelected] = useState([]);
  const [formfield, setFormField] = useState([]);

  // handle change form
  const handleOnChange = (e) => {
    // kirim state ke parent
    handleChange(e);
  };

  //api to show formfield
  const getAPI = () => {
    apiPos
      .get(`v1/formfield`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setFormField(res.data.data.formfields);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // multiple selection
  const options = [];

  formfield.map((item, index) => options.push({ label: item.title, value: item.id, isRequired: false }));

  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  useEffect(() => {
    // console.log("sel rubah", selected);
    handleChangeSelected(selected);
  }, [selected]);

  const ChangeSelect = (e) => {
    // console.log("ini e", e);
    setSelected(e);
  };

  const inputOnChangeSelect = (e, item) => {
    // console.log("inigmn", e.target.value);
    // console.log("iniitem", item);
    // console.log("sel", selected);

    let index = selected.findIndex((i) => i.value == item.value);
    // console.log("index", index);

    // selected[index].isRequired = e.target.value;
    const arr = [...selected];
    arr[index].isRequired = e.target.value;
    setSelected(arr);
  };

  const replaceField = (e) => {
    switch (e) {
      case 1:
        return "Tanggal";
        break;
      case 2:
        return "Alamat";
        break;
      case 5:
        return "lokasi";
        break;
      default:
        return " ";
    }
  };
  return (
    <Fragment>
      {console.log("porm", chooseFields)}
      {form && (
        <Stack spacing={4}>
          <FormControl id="kasigroupId">
            <Stack direction={["row"]} spacing="24px">
              <Box w="25%">
                <FormLabel>Kasi Group Id</FormLabel>
              </Box>
              <Box w="75%">
                <Input type="text" placeholder="...." name="kasigroupId" onChange={handleOnChange} value={form.kasigroupId || ""} />
              </Box>
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack direction={["row"]} spacing="24px">
              <Box w="25%">
                <FormLabel>Name</FormLabel>
              </Box>
              <Box w="75%">
                <Input type="text" placeholder="...." name="name" onChange={handleOnChange} value={form.name || ""} />
              </Box>
            </Stack>
          </FormControl>
          <FormControl id="description">
            <Stack direction={["row"]} spacing="24px">
              <Box w="25%">
                <FormLabel>Description</FormLabel>
              </Box>
              <Box w="75%">
                <Input type="text" placeholder="...." name="description" onChange={handleOnChange} value={form.description || ""} />
              </Box>
            </Stack>
          </FormControl>
          <FormControl id="icon">
            <Stack direction={["row"]} spacing="24px">
              <Box w="25%">
                <FormLabel>Icon</FormLabel>
              </Box>
              <Box w="75%">
                <AspectRatio maxW="500px" ratio={5 / 5}>
                  <Image src={`${envs.imageUrl}/${form.icon}`} alt="Identity Photo" objectFit="cover" />
                </AspectRatio>
              </Box>
            </Stack>
          </FormControl>
          <FormControl id="icon">
            <Stack direction={["row"]} spacing="24px">
              <Box w="25%">
                <FormLabel>New Icon</FormLabel>
              </Box>
              <Box w="75%">
                <Input variant="filled" type="file" size="sm" onChange={onFileChange} />
              </Box>
            </Stack>
          </FormControl>

          <FormControl id="fieldIds">
            <Stack direction={["row"]} spacing="24px">
              <Box w="25%">
                <FormLabel>Field Required</FormLabel>
              </Box>
              <Box w="75%">
                <MultiSelect options={options} value={selected} onChange={ChangeSelect} labelledBy="Select" />
                {/* <HStack> */}
                Telah Dipilih:
                {chooseFields &&
                  chooseFields.map((item, i) => (
                    <Box key={i}>
                      {replaceField(item.fieldId)} [ {item.isRequired ? "Harus isi" : "Tidak Harus Isi"} ],
                    </Box>
                  ))}
                {/* </HStack> */}
              </Box>
            </Stack>
          </FormControl>

          {selected.length > 0 &&
            selected.map((item, index) => (
              <FormControl id="isRequired" key={index}>
                <Stack direction={["row"]} spacing="24px">
                  <Box w="25%">
                    <FormLabel>isRequired {item.label}</FormLabel>
                  </Box>
                  <Box w="75%">
                    <Select placeholder="Select option" name="isRequired" onChange={(e) => inputOnChangeSelect(e, item)}>
                      <option value="true">Harus isi</option>
                      <option value="false">Tidak Harus</option>
                    </Select>
                  </Box>
                </Stack>
              </FormControl>
            ))}
        </Stack>
      )}
    </Fragment>
  );
};

export default ModalKasi;
