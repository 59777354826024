import React, { Fragment, useState, useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Center,
  Tag,
  Input,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { ImCross, ImCheckmark } from "react-icons/im";
import { apiPos } from "../../../utils/axios";
import Cookies from "js-cookie";
import envs from "../../../envs";
import { Redirect } from "react-router-dom";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Stack, Checkbox } from "@chakra-ui/react";
import { boolean } from "yup";
import { Image } from "@chakra-ui/react";

const ModalReject = ({ userID, setalertMassage, setalertMassageFailed, onClose, onCloseReject, getAPI }) => {
  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(false);

  let initialValues = {
    userId: "",
    reason: "",
  };
  //state for title and content
  const [collection, setCollection] = useState(initialValues);
  const [templateRejectMessageSelected, setTemplateRejectMessageSelected] = useState("");
  const templateRejectMessage = [
    {
      id: 1,
      title: "PORTO TIDAK SESUAI",
      desc: "Mohon maaf portofolio anda tidak sesuai. Silahkan registrasi ulang di aplikasi mitra kasisolusi dengan portofolio yang sesuai, \nTerima Kasih. \n-maskas",
    },
    {
      id: 2,
      title: "DATA DIRI TIDAK LENGKAP",
      desc: "Mohon maaf data diri anda belum lengkap, pastikan data diri anda diisi sesuai perintah yang ada dengan tepat. Silahkan registrasi ulang di aplikasi mitra kasisolusi, \nTerima Kasih. \n-maskas",
    },
    {
      id: 3,
      title: "PORTO TIDAK BISA AKSES",
      desc: "Mohon maaf portofolio anda tidak bisa maskas akses Silahkan registrasi ulang di aplikasi mitra kasisolusi dengan portofolio yang sesuai, \nTerima Kasih. \n-maskas",
    },
  ];

  const inputOnChange = (event) => {
    let _text = templateRejectMessageSelected;
    _text = event.target.value;
    setTemplateRejectMessageSelected(_text);
  };

  const handleSubmit = async () => {
    setLoad(true);
    await apiPos
      .patch(`/v1/user/rejectmitra`, collection, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    onCloseReject();
    onClose();

    getAPI();

    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
  };
  useEffect(() => {
    setCollection({
      ...collection,
      userId: userID,
      reason: templateRejectMessageSelected,
    });
    // console.log("ini awalnya", userID);
  }, [templateRejectMessageSelected.length]);

  return (
    <Fragment>
      <Box as={"form"}>
        <Stack spacing={4}>
          <FormControl id="name">
            <Stack direction={["row"]} spacing="24px">
              <Box w="25%">
                <FormLabel height={200}>Pesan</FormLabel>
              </Box>
              <Box w="75%">
                <Textarea type="text" placeholder="...." name="reason" onChange={(event) => inputOnChange(event)} value={templateRejectMessageSelected} minHeight={"200px"} />
              </Box>
            </Stack>
          </FormControl>
          <Stack>
            {templateRejectMessage.map((i, e) => {
              return (
                <Button key={e.toString()} onClick={() => setTemplateRejectMessageSelected(i.desc)}>
                  {i.title}
                </Button>
              );
            })}
          </Stack>
          <br />
          <Stack spacing={10}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
              type="submit"
            >
              {load ? (
                <Stack direction={"row"} spacing={4} align={"center"}>
                  <Spinner />
                  <Text>Loading...</Text>
                </Stack>
              ) : (
                "Kirim"
              )}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default ModalReject;
