import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Flex,
  Grid,
  GridItem,
  Spacer,
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner,
  Badge,
  Center,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import envs from "../../../envs";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../img/LogoKasisolusi.png";
import { apiPos } from "../../../utils/axios";
import Carousel from "react-elastic-carousel";
import Icon from "../../../img/Icon.png";
import { AiFillStar } from "react-icons/ai";
import moneyFormat from "../../../helper/helper";

export default function Product() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const slug = searchParams.get("slug");

  const [product, setProduct] = useState([]);

  const redirectStore = () => {
    // and viewed on an Android Phone...
    if (navigator.userAgent.match(/Android/)) {
      setTimeout(function () {
        window.location.replace("https://play.google.com/store/apps/details?id=id.dreamtechnology.kasisolusi");
      }, 5000);
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      setTimeout(function () {
        window.location.replace("https://apps.apple.com/id/app/kasisolusi/id1582931531");
      }, 5000);
    } else {
    }
  };

  const redirectProduct = () => {
    // and viewed on an Android Phone...
    if (navigator.userAgent.match(/Android/)) {
      setTimeout(function () {
        window.location.replace(`https://client.kasisolusi.id/app/product/${slug}`);
      }, 2000);
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      setTimeout(function () {
        window.location.replace(`kasisolusi://app/product/${slug}`);
      }, 2000);
    } else {
    }
  };

  const getAPIID = () => {
    //get ticket
    apiPos
      .get(`/v1/userkasi?slug=${slug}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setProduct(res.data.data.userkasi[0]);
        // getAPI(res.data.data.livestreams[0].id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAPIID();
  }, []);

  const { toggleColorMode } = useColorMode();
  const formBackground = useColorModeValue("gray.100", "grey.700");
  return (
    <Fragment>
      {console.log(product)}
      <Flex minH={"100vh"} align={"center"} justify={"center"} bg={formBackground}>
        <Box spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Box mb={4}>
              <Image src={Logo} alt="Logo Kasisolusi" />
            </Box>
          </Stack>
          {product && (
            <Center py={12}>
              <Box role={"group"} p={6} maxW={"330px"} w={"full"} boxShadow={"2xl"} rounded={"lg"} pos={"relative"} zIndex={1} bg="gray.300">
                <Box
                  rounded={"lg"}
                  pos={"relative"}
                  height={"230px"}
                  _after={{
                    transition: "all .3s ease",
                    content: '""',
                    w: "full",
                    h: "full",
                    pos: "absolute",
                    top: 5,
                    left: 0,
                    // backgroundImage: `url(${envs.imageUrl}/${product.thumbnail.pathName})`,
                    filter: "blur(15px)",
                    zIndex: -1,
                  }}
                  _groupHover={{
                    _after: {
                      filter: "blur(20px)",
                    },
                  }}
                >
                  {product.thumbnail && <Image rounded={"lg"} height={230} width={282} objectFit={"cover"} src={`${envs.imageUrl}/${product.thumbnail.pathName}`} />}
                </Box>

                <Stack pt={10} align={"center"}>
                  <Text color={"gray.500"} fontSize={"sm"} textTransform={"uppercase"} fontWeight={800}>
                    {product.userkasiName}
                  </Text>
                  {/* <HStack direction={"row"} align={"center"}>
                    <Text fontWeight={500} fontSize={"2xl"}>
                      {product.userkasiName}
                    </Text>
                  </HStack> */}
                  <Stack direction={"row"} align={"center"}>
                    <Center>
                      <Text fontWeight={400} fontSize={"sm"}>
                        {product.userkasiDescription &&
                          product.userkasiDescription.split("\n").map((item, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                {item}
                                <br />
                              </React.Fragment>
                            );
                          })}
                      </Text>
                    </Center>
                  </Stack>
                </Stack>
              </Box>
            </Center>
          )}
          <Heading as="h4" size="md" mb={6}>
            Mitra
          </Heading>
          {product && (
            <Fragment>
              <Center py={6}>
                <Stack borderWidth="1px" borderRadius="lg" w={{ sm: "100%", md: "540px" }} height={{ sm: "476px", md: "20rem" }} direction={{ base: "column", md: "row" }} boxShadow={"2xl"} padding={4}>
                  <Image rounded={"100%"} w={{ sm: "50%", md: "10rem" }} height={{ sm: "276px", md: "10rem" }} src={`${envs.imageUrl}/${product.user && product.user.photo}`} mt="50px" />

                  <Stack flex={1} flexDirection="column" justifyContent="center" alignItems="center" p={1} pt={2}>
                    <Text fontSize={"2xl"} fontWeight={"bold"} fontFamily={"body"}>
                      {product.user && product.user.name}
                    </Text>
                    <Text fontWeight={600} color={"gray.500"} size="sm" mb={4}>
                      {product.user && product.user.city}
                    </Text>
                    {/* <Text textAlign={"center"} px={3}>
                      Actress, musician, songwriter and artist. PM for work inquires or
                      <Link href={"#"} color={"blue.400"}>
                        #tag
                      </Link>
                      me in your posts
                    </Text>
                    <Stack align={"center"} justify={"center"} direction={"row"} mt={6}>
                      <Badge px={2} py={1} fontWeight={"400"}>
                        #art
                      </Badge>
                      <Badge px={2} py={1} fontWeight={"400"}>
                        #photography
                      </Badge>
                      <Badge px={2} py={1} fontWeight={"400"}>
                        #music
                      </Badge>
                    </Stack>

                    <Stack width={"100%"} mt={"2rem"} direction={"row"} padding={2} justifyContent={"space-between"} alignItems={"center"}>
                      <Button
                        flex={1}
                        fontSize={"sm"}
                        rounded={"full"}
                        _focus={{
                          bg: "gray.200",
                        }}
                      >
                        Message
                      </Button>
                      <Button
                        flex={1}
                        fontSize={"sm"}
                        rounded={"full"}
                        bg={"blue.400"}
                        color={"white"}
                        boxShadow={"0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"}
                        _hover={{
                          bg: "blue.500",
                        }}
                        _focus={{
                          bg: "blue.500",
                        }}
                      >
                        Follow
                      </Button>
                    </Stack> */}
                  </Stack>
                </Stack>
              </Center>
            </Fragment>
          )}

          <Box mt={6}>
            <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={2} mb={4}>
              <Box align={"center"} bg={useColorModeValue("white", "gray.700")}>
                <Button onClick={redirectProduct} w="full" colorScheme="linkedin">
                  Buka Kasisolusi
                </Button>
              </Box>
            </Box>
            <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={2}>
              <Box align={"center"} bg={useColorModeValue("white", "gray.700")}>
                <Button onClick={redirectStore} w="full" colorScheme="linkedin">
                  Download Kasisolusi
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Fragment>
  );
}
