import React, { Fragment, useState, useEffect } from "react";
import { Box, useColorModeValue,Stack,Heading, HStack } from "@chakra-ui/react";
import {  useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    StarIcon,Center
    ,Formik,
    Form,
    Field,
    Button,
    Flex,
    Text,
    Link,
    Checkbox,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Spinner,
    Select
  } from "@chakra-ui/react"
  import * as Yup from "yup";
  import axios from "axios";
  import envs from "../../envs";
  import { apiPos } from "../../utils/axios";
  import Cookies from "js-cookie";


function CreatYoutube() {

  const history = useHistory();
  const [text,setText]=useState("");
  const [categories,setCategories]=useState([]);


  let initialValues = {
    pageName: "",
    pageParam:""
  };
    //state for title and content
  const [collection,setCollection]=useState(initialValues)

  let [load, setLoad] = useState(false);


  const handleSubmit = async () => {
    setLoad(true)

    await apiPos.post(`/v1/pagemap/`,collection ,{
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        AppNumber: envs.appNumber,
       
      },
    })
    .then((res) => {
      setLoad(false);
      setTimeout(function(){ history.replace("/pagemap") }, 2000);
     
      console.log(res)
    })
    .catch((e) => {
      setLoad(false);
      console.log(e);
    }); 
    
      // console.log(collection)
  };





  const inputOnChange = (e) => {

    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };







  const{toggleColorMode}= useColorMode();
  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p="4">
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{mb:18}}>
              <Center>Create Page Map</Center>
            </Heading>
            </Stack>
            <br />
            <Box as={'form'}  >
              <Stack spacing={4} >
                <FormControl id="pageName" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Page Name</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder="...." name="pageName" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <FormControl id="pageParam" >
                <Stack direction={["row"]} spacing="24px">
                <Box w="25%">
                  <FormLabel>Page Param</FormLabel>
                </Box>
                <Box w="75%">
                  <Input type="text"  placeholder='example:	{"kasiId": "1", "color": "#000000"}' name="pageParam" onChange={inputOnChange}/>
                </Box>
                </Stack>
                </FormControl>
                <br />
                <Stack spacing={10}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    type="submit">
                    {load ?
                      <Stack direction={'row'} spacing={4} align={'center'}>
                        <Spinner />
                        <Text>Loading...</Text>
                      </Stack> : 'Submit'}
                  </Button>
                </Stack>
              </Stack>
            </Box>            
          </Box>
      </Box>
    </Fragment>
  );
}

export default CreatYoutube;