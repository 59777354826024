import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Flex,
  Spacer,
  Link,
  Tag,
  Input,
  Select,
} from "@chakra-ui/react";
import _ from "lodash";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import moneyFormat from "../../helper/helper";
import envs from "../../envs";
import moment from "moment";
import ModalUser from "./ModalProjectUser";
import ModalProjectStatus from "./ModalProjectStatus";
import ModalCancel from "./ModalProjectCancel";
import { chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import GlobalFilter from "../../helper/globalFilter";
import "../../containers/table/table.css";

function ProjectList() {
  const history = useHistory();
  const [project, setProject] = useState([]);
  const [projectUser, setProjectUser] = useState([]);
  const [projectDetail, setProjectDetail] = useState([]);
  const [load, setLoad] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

  let initialValues = {
    status: "canceled", // harus canceled
    message: "Dibatalkan kasisolusi",
  };

  const [collection, setCollection] = useState(initialValues);

  const downloadFile = (e) => {
    saveAs(`https://kita.kasisolusi.com/exportdocument/revenue?projectid=${e}&token=PB3MrHdFAWhuVDNU8jxGcvAx`); // Put your image url here.
  };

  const getAPI = () => {
    apiPos
      .get(`/v1/mitra/allactiveprojects`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setProject(res.data.data.projects);
        console.log("gmn", res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openModal = (id) => {
    setProjectUser(id);
    onOpen();
  };

  const openModalStatus = (id) => {
    setProjectDetail(id);
    apiPos
      .get(`/v1/project/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        // console.log("detail", res);
        setProjectDetail(res.data.data.projects);
      })
      .catch((e) => {
        console.log(e);
      });
    onOpen3();
  };

  const openModalCancel = (id) => {
    setProjectUser(id);
    // console.log("hyc", id);
    onOpen2();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  const getStatus = (e, modalData) => {
    // console.log(e);
    // return e;
    let statusProject = null;
    let color = null;
    switch (e) {
      case "review":
        return (
          <Button size="sm" colorScheme={"blue"} variant="solid" onClick={() => openModalStatus(modalData)}>
            Menunggu Review
          </Button>
        );
        break;
      case "processing":
        return (
          <Button size="sm" colorScheme={"yellow"} variant="solid" onClick={() => openModalStatus(modalData)}>
            Dalam Proses
          </Button>
        );

        break;
      case "canceled":
        return (
          <Button size="sm" colorScheme={"red"} variant="solid" onClick={() => openModalStatus(modalData)}>
            Canceled
          </Button>
        );

        break;
      case "paid":
        return (
          <Button size="sm" colorScheme={"orange"} variant="solid" onClick={() => openModalStatus(modalData)}>
            Menunggu Konfirmasi / Paid
          </Button>
        );
        break;
      case "approved":
        return (
          <Button size="sm" colorScheme={"green"} variant="solid" onClick={() => openModalStatus(modalData)}>
            Project Selesai
          </Button>
        );
        break;
      case "waiting":
        return (
          <Button size="sm" colorScheme={"purple"} variant="solid" onClick={() => openModalStatus(modalData)}>
            Menunggu Pembayaran
          </Button>
        );
        break;
      default:
        return (
          <Button size="sm" colorScheme={"yellow"} variant="solid" onClick={() => openModalStatus(modalData)}>
            {e}
          </Button>
        );
    }
  };

  // REACT TABLE START
  const projectData = React.useMemo(() => [...project], [project]);

  const projectColumns = React.useMemo(
    () => [
      {
        Header: "ID",
        Footer: "TOTAL",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Price",
        Footer: (info) => {
          const total = React.useMemo(() => info.rows.reduce((sum, row) => parseFloat(row.original.price) + parseFloat(sum), 0), [info.rows]);
          return <>{moneyFormat(total)}</>;
        },
        accessor: "price",
        Cell: (props) => <Box w="120px">{moneyFormat(props.value)}</Box>,
      },
      {
        Header: "Tanggal Transaksi",
        accessor: "projectStart",
        Cell: ({ row }) => `${moment(new Date(row.original.createdAt)).add(7, "h").utc().format("MMMM Do YYYY HH:mm:ss ")} WIB`,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => getStatus(row.original.status, row.original.id),
      },

      {
        Header: "Mitra",
        accessor: "talent",
        Cell: ({ row }) => (
          <Button size="xs" colorScheme="teal" onClick={() => openModal(row.original.talent)}>
            {row.original.talent.name}
          </Button>
        ),
      },
      {
        Header: "Client",
        accessor: "user",
        Cell: ({ row }) => (
          <Button size="xs" colorScheme="teal" onClick={() => openModal(row.original.user)}>
            {row.original.user.name}
          </Button>
        ),
      },
      // {
      //   Header: "Invoice",
      //   accessor: "id",
      //   Cell: ({ row }) => console.log("ini row", row),
      //   // <Button onClick={() => downloadFile(row.original.id)} colorScheme="teal">
      //   //   Download
      //   // </Button>
      // },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "ids",
        Header: "Invoice",
        Cell: ({ row }) => (
          <Fragment>
            <Button size="sm" onClick={() => downloadFile(row.original.id)} colorScheme="teal">
              Download
            </Button>
          </Fragment>
        ),
      },
      {
        id: "Action",
        Header: "Action",
        Cell: ({ row }) => (
          <Fragment>
            {row.original.status == "processing" ? (
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => {
                  openModalCancel(row.original.id);
                  // console.log("ini rowsss", row);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button size="sm" colorScheme="red" isDisabled>
                Cancel
              </Button>
            )}
          </Fragment>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable({ columns: projectColumns, data: projectData }, useGlobalFilter, useSortBy, tableHooks, usePagination);

  const { pageIndex, pageSize } = state;
  // REACT TABLE END

  // handle on change untuk di kirim ke component ModalProjectCancel
  const handleOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  const submitCancel = async (e) => {
    setLoad(true);
    await apiPos
      .post(`/v1/project/cancel/${projectUser}`, collection, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    onClose2();
    getAPI();
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
  };

  return (
    <Fragment>
      {/* {console.log("ini col", collection)} */}
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>List project</Center>
            </Heading>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Project Cancellation Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Project Cancellation Failed
            </Alert>
          )}

          <Fragment>
            <br />
            <Box mb={2}>
              <Flex>
                <Box mr={2}>Search : </Box> <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
              </Flex>
            </Box>

            <table {...getTableProps()}>
              <thead>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {
                              // Render the header
                              column.render("Header")
                            }
                            <span>{column.isSorted ? column.isSortedDesc ? <TriangleDownIcon aria-label="sorted descending" /> : <TriangleUpIcon aria-label="sorted ascending" /> : ""}</span>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
              <tfoot>
                {footerGroups.map((footerGroups) => (
                  <tr {...footerGroups.getFooterGroupProps()}>
                    {footerGroups.headers.map((column) => (
                      <td {...column.getFooterGroupProps}>{column.render("Footer")}</td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
            <br />
            <Box>
              <chakra.span>
                page {""}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}{" "}
                </strong>
              </chakra.span>
              <Button onClick={() => gotoPage(0)} size={"sm"} disabled={!canPreviousPage}>
                {"<<"}
              </Button>{" "}
              <Button onClick={() => previousPage()} size={"sm"} disabled={!canPreviousPage}>
                Previous
              </Button>{" "}
              <Button onClick={() => nextPage()} size={"sm"} disabled={!canNextPage}>
                Next
              </Button>{" "}
              <Button onClick={() => gotoPage(pageCount - 1)} size={"sm"} disabled={!canNextPage}>
                {">>"}
              </Button>{" "}
              <chakra.span>
                Go to page: {""}
                <Input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(pageNumber);
                  }}
                  style={{ width: "50px" }}
                />
              </chakra.span>
              <Stack spacing={2}>
                <Box w="120px" h="40px">
                  <Select size="sm" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                    {[10, 25, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Stack>
            </Box>

            {/* MODAL 2 START */}
            <Modal onClose={onClose2} isOpen={isOpen2} motionPreset="slideInBottom">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitCancel();
                  getAPI();
                }}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Cancellation</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <ModalCancel handleChange={handleOnChange} />
                  </ModalBody>
                  <ModalFooter>
                    <Flex>
                      <Spacer />
                      <Box p="4">
                        <Button
                          bg={"blue.400"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          type="submit"
                        >
                          {load ? (
                            <Stack direction={"row"} spacing={4} align={"center"}>
                              <Spinner />
                              <Text>Loading...</Text>
                            </Stack>
                          ) : (
                            "Kirim"
                          )}
                        </Button>
                      </Box>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
            {/* MODAL 2 END */}
          </Fragment>
          {/* MODAL START */}
          <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // verifikasiAPI()
                getAPI();
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Detail </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <ModalUser detailUser={projectUser} />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
          {/* MODAL END */}
          {/* MODAL 3 START */}
          <Modal onClose={onClose3} isOpen={isOpen3} motionPreset="slideInBottom">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // verifikasiAPI()
                getAPI();
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Detail Project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <ModalProjectStatus detailProject={projectDetail} />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose3}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
          {/* MODAL 3 END */}
        </Box>
      </Box>
    </Fragment>
  );
}

export default ProjectList;
