import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useColorModeValue,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Stack,
  Spinner,
  Text,
  Center,
  Flex,
  Spacer,
  Link,
  Image,
  AspectRatio,
} from "@chakra-ui/react";
import moment from "moment";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Cookies from "js-cookie";
import { apiPos } from "../../utils/axios";
import envs from "../../envs";
import ModalKasi from "./ModalKasi.js";

function KasiList() {
  const history = useHistory();
  const [kasi, setKasi] = useState([]);
  const [load, setLoad] = useState(false);
  const [alertMassage, setalertMassage] = useState(false);
  const [alertMassageFailed, setalertMassageFailed] = useState(false);
  const [alertMassageUpdate, setalertMassageUpdate] = useState(false);
  const [alertMassageFailedUpdate, setalertMassageFailedUpdate] = useState(false);
  const [selected, setSelected] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState(null);
  const [chooseField, setChooseField] = useState([]);

  let initialValues = {
    kasigroupId: "",
    name: "",
    description: "",
    // fieldRequired: "",
    fieldIds: [],
    icon: null,
  };
  //state for title and content
  const [collection, setCollection] = useState(initialValues);

  // handle on change untuk di kirim ke component ModalCreate
  const handleOnChange = (e) => {
    setCollection({
      ...collection,
      [e.target.name]: e.target.value,
    });
  };

  const updateKasi = async () => {
    setLoad(true);
    var bodyFormData = new FormData();
    bodyFormData.append("kasigroupId", collection.kasigroupId);
    bodyFormData.append("name", collection.name);
    bodyFormData.append("description", collection.description);
    // bodyFormData.append("fieldRequired", collection.fieldRequired);
    let fieldIdsNew = selected.map((item) => ({ fieldId: item.value, isRequired: item.isRequired }));
    bodyFormData.append("fieldIds", JSON.stringify(fieldIdsNew));
    if (selectedFile != null) {
      bodyFormData.append("icon", selectedFile);
    }
    // else {
    //   bodyFormData.append("icon", `${envs.imageUrl}/${collection.icon}`);
    // }

    // Display the key/value pairs
    for (var pair of bodyFormData.entries()) {
      console.log(pair[0] + ",he " + pair[1]);
    }
    console.log("cek", selectedFile);
    await apiPos
      .patch(`/v1/kasi/${collection.id}`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        console.log(res);
        setalertMassageUpdate(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailedUpdate(true);
      });
    setLoad(false);
    onClose();
    getAPI();
    setTimeout(function () {
      setalertMassageUpdate(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailedUpdate(false);
    }, 3000);
    setCollection(initialValues);
    setSelectedFile(null);
  };

  const getAPI = () => {
    apiPos
      .get(`/v1/kasi`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setKasi(res.data.data.kasi);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteKasi = async (e) => {
    setalertMassage(false);
    setalertMassageFailed(false);
    setLoad(true);

    await apiPos
      .delete(`/v1/kasi/${e}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setalertMassage(true);
      })
      .catch((e) => {
        console.error(e.response.data);
        setalertMassageFailed(true);
      });
    setLoad(false);
    setTimeout(function () {
      setalertMassage(false);
    }, 3000);
    setTimeout(function () {
      setalertMassageFailed(false);
    }, 3000);
    getAPI();
  };

  //api to show table all user
  useEffect(() => {
    // request to API
    getAPI();
  }, []);

  const openModal = (id) => {
    //request API for modal
    //api to show list
    apiPos
      .get(`/v1/kasi/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          AppNumber: envs.appNumber,
        },
      })
      .then((res) => {
        setCollection(res.data.data.kasi);
        setChooseField(res.data.data.kasi.kasifield);
        console.log("k", res.data.data.kasi.kasifield);
      })
      .catch((e) => {
        console.log(e);
      });

    onOpen();
  };

  // On file select (from the pop up)
  const onFileUpdate = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const selectedModal = (e) => {
    // Update the state
    setSelected(e);
    console.log("ini yang di list", e);
  };

  return (
    <Fragment>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")} ml={{ base: 0, md: 60 }} p="4">
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
          <Stack spacing={6}>
            <Heading as="h1" size="lg" isTruncated style={{ mb: 18 }}>
              <Center>Available service List </Center>
            </Heading>
            <Flex>
              <Spacer />
              <Box>
                <Button colorScheme="blue" size="md" onClick={() => history.replace("/createkasi")}>
                  + Add
                </Button>
              </Box>
            </Flex>
          </Stack>
          {alertMassage && (
            <Alert status="success">
              <AlertIcon />
              Delete Service Success
            </Alert>
          )}
          {alertMassageFailed && (
            <Alert status="error">
              <AlertIcon />
              Delete Service Failed
            </Alert>
          )}
          {alertMassageUpdate && (
            <Alert status="success">
              <AlertIcon />
              Update Service Success
            </Alert>
          )}
          {alertMassageFailedUpdate && (
            <Alert status="error">
              <AlertIcon />
              Update Service Failed
            </Alert>
          )}
          <Fragment>
            <br />
            <Table variant="striped" colorScheme="blackAlpha" size="sm" border="1px" borderColor="gray.200">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Group ID</Th>
                  <Th>Icon</Th>
                  <Th>Name</Th>
                  <Th>Description</Th>
                  <Th>Field Required</Th>
                  <Th>Created At</Th>
                  <Th>Updated At</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {kasi &&
                  kasi.map((item) => {
                    return (
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>
                        <Td>{item.kasigroupId}</Td>
                        <Td>
                          <AspectRatio maxW="500px" ratio={5 / 5}>
                            <Image src={`${envs.imageUrl}/${item.icon}`} alt="Identity Photo" objectFit="cover" />
                          </AspectRatio>
                        </Td>
                        <Td>{item.name}</Td>
                        <Td>{item.description}</Td>
                        <Td>
                          {/* {item.fieldRequired} */}
                          {/* {`${envs.imageUrl}/${item.icon}`} */}
                        </Td>
                        <Td>{moment(item.createdAt).utc().format("LLLL")}</Td>
                        <Td>{moment(item.updatedAt).utc().format("LLLL")}</Td>
                        <Td>
                          {load ? (
                            <Button isLoading loadingText="Wait..."></Button>
                          ) : (
                            <Fragment>
                              <Button
                                justify-content="flex-start"
                                colorScheme="red"
                                size="sm"
                                onClick={() => {
                                  if (window.confirm("Are you sure you wish to delete this item?")) deleteKasi(item.id);
                                }}
                              >
                                Delete
                              </Button>
                              <Button colorScheme="blue" size="sm" onClick={() => openModal(item.id)}>
                                Edit
                              </Button>
                            </Fragment>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            {/* MODAL START */}
            <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  updateKasi();
                  getAPI();
                }}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Edit </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <ModalKasi handleChange={handleOnChange} form={collection || ""} chooseFields={chooseField || ""} onFileChange={onFileUpdate} handleChangeSelected={selectedModal} />
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                      Close
                    </Button>
                    <Button variant="solid" colorScheme="red" type="submit">
                      {load ? (
                        <Stack direction={"row"} spacing={4} align={"center"}>
                          <Spinner />
                          <Text>Loading...</Text>
                        </Stack>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
            {/* MODAL END */}
          </Fragment>
        </Box>
      </Box>
    </Fragment>
  );
}

export default KasiList;
